define("a24-staffshift-subdomain-sysadmin/services/a24-enums-subdomain-sysadmin", ["exports", "a24-ember-lookup/services/a24-enums-base"], function (exports, _a24EnumsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _a24EnumsBase.default.extend({
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSubdomainSysAdminStringsService: Ember.inject.service("a24-strings-subdomain-sysadmin"),

        init: function () {
            this._super(...arguments);

            this.set("contexts", {
                "AGENCY": {
                    "backend": "agency",
                    "frontend": this.get("_objSubdomainSysAdminStringsService").getString("agency")
                },
                "AGENCY_GROUP": {
                    "backend": "agency-group",
                    "frontend": this.get("_objSubdomainSysAdminStringsService").getString("agencyGroup")
                }
            });
            this.set("skillStatuses", {
                "ACTIVE": {
                    "backend": "active",
                    "frontend": this.get("_objStringsService").getString("active")
                },
                "INACTIVE": {
                    "backend": "inactive",
                    "frontend": this.get("_objStringsService").getString("inactive")
                }
            });
            this.set("qualificationStatuses", {
                "ACTIVE": {
                    "backend": "active",
                    "frontend": this.get("_objStringsService").getString("active")
                },
                "INACTIVE": {
                    "backend": "inactive",
                    "frontend": this.get("_objStringsService").getString("inactive")
                }
            });
            this.set("registrationBodyStatuses", {
                "ACTIVE": {
                    "backend": "active",
                    "frontend": this.get("_objStringsService").getString("active")
                },
                "INACTIVE": {
                    "backend": "inactive",
                    "frontend": this.get("_objStringsService").getString("inactive")
                }
            });
        }
    });
});