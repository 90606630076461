define("a24-staffshift-subdomain-sysadmin/components/page-registration-bodies", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-sysadmin/config/environment"], function (exports, _didRenderChangesMixin, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        objQueryParams: null,
        _sUrlBase: null,
        _bIsAdd: false,
        _bPageLoading: false,
        _arrStatuses: null,
        _arrCountryDropdown: null,
        _arrDisplayPositionTypes: null,
        _objSelectedRegistrationBody: null,
        _bShowAddEditRegistrationBodyPopup: false,
        _bShowAddEditRegistrationBodyUnsavedData: false,
        _bShowLoadFailedPopup: false,
        _sAddEditRegistrationBodyHeaderPopup: null,
        _objRestSystem: Ember.inject.service("rest-system"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSubdomainSysAdminEnumsService: Ember.inject.service("a24-enums-subdomain-sysadmin"),
        _objSubdomainSysAdminStringsService: Ember.inject.service("a24-strings-subdomain-sysadmin"),
        _objCandidateTypeTabsService: Ember.inject.service("candidate-type-tabs"),
        _objEnumsService: Ember.inject.service("a24-enums"),
        _objFabButtonService: Ember.inject.service("fab-button"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objNavigationService: Ember.inject.service("navigation"),

        init: function () {
            this._super(...arguments);

            this.set("_sUrlBase", _environment.default.sRestApiBaseUrl + "/v1/system/registration-body");

            this.set("_arrStatuses", this.get("_objSubdomainSysAdminEnumsService").getList("registrationBodyStatuses"));

            this.set("_arrCountryDropdown", this.get("_objEnumsService").getList("country"));

            this.get("_objTitleBarService").setTitleBar(this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodies"));

            this.set("objChildConfig", {
                "objChildren.name": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                },
                "objChildren.status": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                }
            });

            this._doServiceCalls();
        },

        _doServiceCalls: function () {
            this.set("_bPageLoading", true);

            this.get("_objRestSystem").getPositionTypes(this, "_objPositionTypesResponse", arrData => {
                if (!a24Core.isEmpty(arrData)) {
                    var arrPositionTypes = this.get("_objCandidateTypeTabsService").generateCandidatesTypes(arrData);

                    arrPositionTypes = arrPositionTypes.map(objData => {
                        var objPositionType = {
                            sTitle: objData.name,
                            sValue: objData.code
                        };

                        return objPositionType;
                    });

                    this.set("_arrDisplayPositionTypes", arrPositionTypes.sortBy("sTitle"));
                } else {
                    this.set("_arrDisplayPositionTypes", []);
                }

                this._setupDatagridConfig();

                this.set("_bPageLoading", false);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], () => /*sCode, sStatus, objErrorThrown, objError*/{
                this.set("_bPageLoading", false);
                this.set("_bShowLoadFailedPopup", true);
            }));
        },

        _setupDatagridConfig: function () {
            var objThis = this;

            this.get("_objFabButtonService").showFabButton(() => {
                objThis.set("_bShowAddEditRegistrationBodyPopup", true);
            });

            var arrPositionTypesDropdown = this.get("_arrDisplayPositionTypes");

            var arrStatusesDropdown = this.get("_arrStatuses").map(objStatus => {
                return {
                    sTitle: objStatus.sLabel,
                    sValue: objStatus.sValue
                };
            });

            var arrDataGridHeaderConfig = [{
                iUniqueColNum: 1,
                sTitle: this.get("_objStringsService").getString("name"),
                sProperty: "name",
                sFilterType: "text",
                bText: true,
                iWidth: 35,
                bHideEmptyCheck: true,
                bIsClickable: true
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objSubdomainSysAdminStringsService").getString("positionType"),
                sProperty: "position_types",
                sFilterType: "dropdownMulti",
                arrMappingData: arrPositionTypesDropdown,
                bMapping: true,
                iWidth: 35,
                bCustom: true,
                bHideEmptyCheck: true,
                bStopColumnSort: true,
                funcTableCustomVal: function (objHeader, objRowData) {
                    var arrPositionTypesName = [];
                    var arrPositionTypes = objRowData[objHeader.sProperty];

                    if (!a24Core.isEmpty(arrPositionTypes)) {
                        for (var i = 0; i < arrPositionTypes.length; i++) {
                            var objPositionType = arrPositionTypesDropdown.findBy("sValue", arrPositionTypes[i]);
                            if (!a24Core.isEmpty(objPositionType)) {
                                arrPositionTypesName.push(objPositionType.sTitle);
                            }
                        }
                    }

                    return arrPositionTypesName.join(", ");
                }
            }, {
                iUniqueColNum: 3,
                sTitle: this.get("_objStringsService").getString("status"),
                sProperty: "status",
                sFilterType: "dropdown",
                arrMappingData: arrStatusesDropdown,
                bHideEmptyCheck: true,
                bStopColumnSort: true,
                bMapping: true,
                iWidth: 5
            }, {
                iUniqueColNum: 4,
                sTitle: this.get("_objStringsService").getString("countryCode"),
                sProperty: "country_code",
                sFilterType: "text",
                bStopFiltering: true,
                bStopColumnSort: true,
                bText: true,
                iWidth: 25
            }];

            var arrSingleItemActions = [{
                sTitle: this.get("_objStringsService").getString("view"),
                sIcon: "visibility",
                executeCardAction: function () {
                    objThis.doRegisteredBodyView(objThis.get("_arrSelectedItems")[0]._id);
                }
            }];

            this.set("_objDatagridConfig", {
                sDatagridHeader: this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodies"),
                iMaxHeight: 40 * 27,
                bSelectColumn: true,
                bEmitSelectRowEvent: true,
                bHasReloadButton: true,
                arrSingleItemSelectActions: arrSingleItemActions,
                arrHeaderConfig: arrDataGridHeaderConfig
            });
        },

        doRegisteredBodyView: function (sRegistrationBodyId) {
            Ember.run.next(() => {
                this.get("_objNavigationService").navigateWithinContext("registration-body-view", {
                    "registration-body-view": [sRegistrationBodyId]
                });
            });
        },

        _onItemsSelect: function (arrSelectedItems) {
            if (!a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length === 1) {
                this.set("_objSelectedRegistrationBody", arrSelectedItems[0]);
            } else {
                this.set("_objSelectedRegistrationBody", null);
            }

            this.set("_arrSelectedItems", arrSelectedItems);
        },
        profRegistrationBodyAdded: function (objResponse) {
            this.set("_bShowAddEditRegistrationBodyPopup", false);
            this.set("_bPageLoading", true);

            Ember.run.later(() => {
                this.doRegisteredBodyView(objResponse._id);
            }, 600);
        },

        actions: {
            cardDatagridTableNoneSelected: function () {
                this._onItemsSelect(null);
            },

            cardDatagridTableOneSelected: function (arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },

            cardDatagridTableMultiSelected: function (arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },

            cardDatagridTableCell: function (sColumnName, objRowData) {
                if (sColumnName === "name") {
                    var sRegistrationBodyId = objRowData._id;

                    this.doRegisteredBodyView(sRegistrationBodyId);
                }
            },

            onProfRegistrationBodiesAddedUpdatedAction: function (objResponse) {
                this.profRegistrationBodyAdded(objResponse);
            },

            onProfRegAddPopupClose: function () {
                this.set("_bShowAddEditRegistrationBodyPopup", false);
            }
        }
    });
});