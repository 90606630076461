define("a24-staffshift-subdomain-sysadmin/components/chatter-right-container", ["exports", "a24-ember-staffshift-core/components/chatter-right-container"], function (exports, _chatterRightContainer) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _chatterRightContainer.default;
    }
  });
});