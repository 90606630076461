define("a24-staffshift-subdomain-sysadmin/components/page-social-link-types", ["exports", "a24-staffshift-subdomain-sysadmin/config/environment", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _environment, _inputFormElement, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        classNames: ["page-social-link-types"],
        objQueryParams: null,
        _sUrlBase: null,
        _sIcon: null,
        _bIsAdd: false,
        _bPageLoading: false,
        _bSocialLinkTypesDisabled: false,
        _objSelectedSocialLinkType: null,
        _arrSocialIcons: null,
        _objSelectedIcon: null,

        _objOriginalData: null,
        _objRestSocialLinkService: Ember.inject.service("rest-social-link-types"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSubdomainSysAdminStringsService: Ember.inject.service("a24-strings-subdomain-sysadmin"),
        _objFabButtonService: Ember.inject.service("fab-button"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objEnumsStaffshiftCore: Ember.inject.service("a24-enums-staffshift-core"),
        _objStringsStaffshiftCore: Ember.inject.service("a24-strings-staffshift-core"),

        _bShowAddEditSocialLinkTypesPopup: false,
        _bAddEditSocialLinkTypesPopupLoading: false,
        _sAddEditSocialLinkTypesHeaderPopup: null,
        _objSpecialAddEditSocialLinkTypesPopupAction: null,
        _bShowAddEditSocialLinkTypesUnsavedData: false,

        _objSocialLinkTypeCreatePromise: null,
        _objSocialLinkTypeUpdatePromise: null,
        init: function () {
            this._super(...arguments);

            this.set("_sUrlBase", _environment.default.sRestApiBaseUrl + "/v1/social-link-types");

            this.set("_objSpecialAddEditSocialLinkTypesPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: () => {
                    this._onPopupAddEditSocialLinkTypesCancel();
                }
            });

            this.get("_objTitleBarService").setTitleBar(this.get("_objSubdomainSysAdminStringsService").getString("socialLinkTypes"));

            this.set("_arrSocialIcons", this.get("_objEnumsStaffshiftCore").getList("socialIcons"));

            this.set("objChildConfig", {
                "objChildren._id": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addMinLength(objMixin, 3);
                    a24Validation.addMaxLength(objMixin, 50);
                },
                "objChildren.name": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addMinLength(objMixin, 3);
                    a24Validation.addMaxLength(objMixin, 50);
                }
            });

            this._setupDatagridConfig();
        },
        onInputValueChanged: function (arrChildRef) {
            this._super(...arguments);

            // Get the reference of the field that changed
            var sLookup = arrChildRef.join(".");

            if (sLookup === "objChildren.icon") {
                var sIconName = this.get("objChildren.icon.mValue.sValue");
                this.set("_sIcon", Ember.String.htmlSafe(this.get("_objEnumsStaffshiftCore").getMaterialSvgIcon(sIconName)));
            }
        },

        _setupDatagridConfig: function () {
            var objThis = this;

            this.get("_objFabButtonService").showFabButton(() => {
                this._setAddEditPopup();
            });

            var arrDataGridHeaderConfig = [{
                iUniqueColNum: 1,
                sTitle: this.get("_objStringsStaffshiftCore").getString("type"),
                sProperty: "name",
                sFilterType: "text",
                bText: true,
                iWidth: 40,
                bHideEmptyCheck: true
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objSubdomainSysAdminStringsService").getString("icon"),
                sProperty: "icon",
                sFilterType: "text",
                iWidth: 10,
                bCustom: true,
                bStopFiltering: true,
                bStopColumnSort: true,
                funcTableCustomVal: function (objHeader, objRowData) {
                    var sIconSvg = objThis.get("_objEnumsStaffshiftCore").getMaterialSvgIcon(objRowData[objHeader.sProperty]);
                    return Ember.String.htmlSafe(`<i class="font-lg">${sIconSvg}</i>`);
                }
            }, {
                iUniqueColNum: 3,
                sTitle: this.get("_objSubdomainSysAdminStringsService").getString("id"),
                sProperty: "_id",
                sFilterType: "text",
                iWidth: 40,
                bText: true,
                bStopFiltering: true
            }, {
                iUniqueColNum: 4,
                sTitle: this.get("_objStringsService").getString("disabled"),
                sProperty: "disabled",
                sFilterType: "bool",
                bStopColumnSort: true,
                bHideEmptyCheck: true,
                bBool: true,
                bMapping: true,
                iWidth: 10,
                arrMappingData: [{
                    sValue: "true",
                    sTitle: this.get("_objStringsService").getString("yes")
                }, {
                    sValue: "false",
                    sTitle: this.get("_objStringsService").getString("no")
                }]
            }];

            var arrSingleItemActions = [{
                sTitle: this.get("_objStringsService").getString("edit"),
                sIcon: "edit",
                executeCardAction: function () {
                    objThis._setAddEditPopup(objThis.get("_objSelectedSocialLinkType"));
                }
            }];

            this.set("_objDatagridConfig", {
                sDatagridHeader: this.get("_objSubdomainSysAdminStringsService").getString("socialLinkTypes"),
                iMaxHeight: 40 * 27,
                bSelectColumn: true,
                bEmitSelectRowEvent: true,
                bHasReloadButton: true,
                arrSingleItemSelectActions: arrSingleItemActions,
                arrHeaderConfig: arrDataGridHeaderConfig
            });
        },
        _setAddEditPopup: function (objSocialLinkType) {
            this.clearAllErrorMessages();
            this.clearForm();
            this.set("_sIcon", null);
            this.set("_objSelectedIcon", null);

            if (!a24Core.isEmpty(objSocialLinkType)) {
                this.set("_bIsAdd", false);

                this.set("_sAddEditSocialLinkTypesHeaderPopup", this.get("_objStringsService").getTokenString("editItem", this.get("_objSubdomainSysAdminStringsService").getString("socialLinkTypes")));

                this._populateInputFields(objSocialLinkType);
            } else {
                this.set("_bIsAdd", true);

                this.set("_sAddEditSocialLinkTypesHeaderPopup", this.get("_objStringsService").getTokenString("addItem", this.get("_objSubdomainSysAdminStringsService").getString("socialLinkTypes")));
            }

            this.set("_bAddEditSocialLinkTypesPopupLoading", false);
            this.set("_bShowAddEditSocialLinkTypesPopup", true);

            Ember.run.next(() => {
                Ember.run.schedule("afterRender", () => {
                    this.set("_objOriginalData", this.getPlainFormValueObject());
                });
            });
        },
        _populateInputFields: function (objSocialLinkTypes) {
            if (!a24Core.isEmpty(objSocialLinkTypes._id)) {
                this.get("objChildren._id").setValueFromOutside(objSocialLinkTypes._id);
            }
            if (!a24Core.isEmpty(objSocialLinkTypes.name)) {
                this.get("objChildren.name").setValueFromOutside(objSocialLinkTypes.name);
            }
            if (!a24Core.isEmpty(objSocialLinkTypes.icon)) {
                var sIconName = objSocialLinkTypes.icon;
                var objSelectedIcon = this.get("_arrSocialIcons").findBy("sValue", sIconName);

                this.set("_sIcon", Ember.String.htmlSafe(this.get("_objEnumsStaffshiftCore").getMaterialSvgIcon(sIconName)));
                if (!a24Core.isEmpty(objSelectedIcon)) {
                    this.set("_objSelectedIcon", Ember.copy(objSelectedIcon, true));
                }
            }

            this.set("_bSocialLinkTypesDisabled", objSocialLinkTypes.disabled === true);
        },

        _getPayload: function () {
            var objData = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());

            return objData;
        },

        _createSocialLinkType: function () {
            this.set("_bAddEditSocialLinkTypesPopupLoading", true);

            var objData = this._getPayload();

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objSubdomainSysAdminStringsService").getString("socialLinkType")));

            this.get("_objRestSocialLinkService").createSocialLinkType(this, "_objSocialLinkTypeCreatePromise", objData, () => {
                this._setSuccessResponse(this.get("_objStringsService").getTokenString("successAddItem", this.get("_objSubdomainSysAdminStringsService").getString("socialLinkType")));
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], (sCode, sStatus, objErrorThrown, objError) => {
                this._setErrorResponse(sCode, objError, this.get("_objStringsService").getTokenString("failAddItem", this.get("_objSubdomainSysAdminStringsService").getString("socialLinkType")));
            }));
        },

        _updateSocialLinkType: function () {
            this.set("_bAddEditSocialLinkTypesPopupLoading", true);

            var objData = this._getPayload();

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objSubdomainSysAdminStringsService").getString("socialLinkType")));

            this.get("_objRestSocialLinkService").updateSocialLinkType(this, "_objSocialLinkTypeUpdatePromise", this.get("_objSelectedSocialLinkType._id"), objData, () => {
                this._setSuccessResponse(this.get("_objStringsService").getTokenString("successUpdateItem", this.get("_objSubdomainSysAdminStringsService").getString("socialLinkType")));
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], (sCode, sStatus, objErrorThrown, objError) => {
                this._setErrorResponse(sCode, objError, this.get("_objStringsService").getTokenString("failUpdateItem", this.get("_objSubdomainSysAdminStringsService").getString("socialLinkType")));
            }));
        },
        _setErrorResponse: function (sCode, objError, sSnackbarMessage) {
            if (sCode === "MIN_LENGTH") {
                this.setResponseError(objError.arrPath, "validationRestMinLength");
            } else if (sCode === "PATTERN" || sCode === "INVALID_FORMAT" || sCode === "INVALID_TYPE") {
                this.setResponseError(objError.arrPath, "validationRestInvalidFormat");
            } else {
                this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            }
            this.set("_bAddEditSocialLinkTypesPopupLoading", false);
        },
        _setSuccessResponse: function (sSnackbarMessage) {
            this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            this.set("_bShowAddEditSocialLinkTypesPopup", false);
            this.set("_bSocialLinkTypesDisabled", false);
            this._refreshSocialLinkTypes();
        },

        _refreshSocialLinkTypes: function () {
            // Put the page in loading
            this.set("_bPageLoading", true);

            Ember.run.next(() => {
                Ember.run.schedule("afterRender", () => {
                    // Take page out of loading
                    this.set("_bPageLoading", false);
                });
            });
        },
        _onPopupAddEditSocialLinkTypesCancel: function () {
            if (this.get("_bAddEditSocialLinkTypesPopupLoading")) {
                return;
            }

            var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

            if (bDataMatches) {
                this.set("_bShowAddEditSocialLinkTypesPopup", false);
                this.set("_bSocialLinkTypesDisabled", false);
            } else {
                this.set("_bShowAddEditSocialLinkTypesUnsavedData", true);
            }
        },
        _onItemsSelect: function (arrSelectedItems) {
            if (!a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length === 1) {
                this.set("_objSelectedSocialLinkType", arrSelectedItems[0]);
            } else {
                this.set("_objSelectedSocialLinkType", null);
            }
        },
        actions: {
            cardDatagridTableNoneSelected: function () {
                this._onItemsSelect(null);
            },

            cardDatagridTableOneSelected: function (arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },

            cardDatagridTableMultiSelected: function (arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },

            onAddEditSocialLinkTypesPopupUnsavedConfirm: function () {
                this.set("_bShowAddEditSocialLinkTypesUnsavedData", false);
                this.set("_bShowAddEditSocialLinkTypesPopup", false);
                this.set("_bSocialLinkTypesDisabled", false);
            },

            onPopupAddEditSocialLinkTypesCancel: function () {
                this._onPopupAddEditSocialLinkTypesCancel();
            },
            onPopupAddEditSocialLinkTypesConfirm: function () {
                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    if (this.get("_bIsAdd")) {
                        this._createSocialLinkType();
                    } else {
                        this._updateSocialLinkType();
                    }
                }
            }
        }
    });
});