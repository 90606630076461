define("a24-staffshift-subdomain-sysadmin/services/a24-constants-form-inputs", ["exports", "a24-ember-form-inputs/services/a24-constants-form-inputs"], function (exports, _a24ConstantsFormInputs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _a24ConstantsFormInputs.default;
    }
  });
});