define("a24-staffshift-subdomain-sysadmin/components/page-registration-body-view", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        _sProfRegistrationBodyId: null,
        _arrReloadActions: null,
        _arrBodyHeaderActions: null,
        _arrBodyStatusHeaderActions: null,
        _arrEntryHeaderActions: null,
        _arrPositionTypes: null,
        _bLoading: null,
        _bServerError: null,
        _bIsAdding: false,
        _iResultCount: null,

        _sRegBodyStatusId: null,
        _sRegBodyEntryId: null,
        _sRegBodyEntryStatusId: null,

        _objStringsService: Ember.inject.service("a24-strings"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objSubdomainSysAdminStringsService: Ember.inject.service("a24-strings-subdomain-sysadmin"),
        _objSubdomainSysAdminEnumsService: Ember.inject.service("a24-enums-subdomain-sysadmin"),
        _objCandidateTypeTabsService: Ember.inject.service("candidate-type-tabs"),
        _objEnumsService: Ember.inject.service("a24-enums"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objTitleBarService: Ember.inject.service("title-bar"),

        _objRestSystemRegistrationBodyService: Ember.inject.service("rest-system-registration-body"),
        _objSystemService: Ember.inject.service("rest-system"),

        _bDisplayProfRegistrationBodyReloadCard: false,
        _bDisplayProfRegistrationBodyStatusesReloadCard: false,
        _bDisplayProfRegistrationBodyEntriesReloadCard: false,
        _bDisplayProfRegistrationBodyEntriesStatusesReloadCard: false,

        _bShowProfRegBodyEdit: false,
        _bShowProfRegBodyStatusAddEdit: false,
        _bShowProfRegBodyEntryAddEdit: false,
        _bShowProfRegBodyEntryStatusAddEdit: false,
        _bShowUnsavedData: false,

        _bAddEditRegistrationBodyStatusPopupLoading: true,
        _bAddEditRegBodyEntryPopupLoading: true,
        _bAddEditRegBodyEntryStatusPopupLoading: true,

        _objSystemServicePromise: null,
        _objProfRegisteredBodyGetPromise: null,
        _objProfRegisteredBodyStatusesGetPromise: null,
        _objProfRegisteredBodyEntriesGetPromise: null,
        _objProfRegisteredBodyEntriesStatusesGetPromise: null,

        _objRegBodyStatusCreatePromise: null,

        _objSpecialAddEditRegBodyStatusPopupAction: null,
        _objSpecialAddEditRegBodyEntryPopupAction: null,
        _objSpecialAddEditRegBodyEntryStatusPopupAction: null,

        _objOriginalData: null,

        _arrProfRegBody: null,
        _arrProfRegistrationBody: null,
        _arrProfRegistrationBodyStatuses: null,
        _arrProfRegistrationBodyEntries: null,
        _arrProfRegistrationBodyEntriesStatuses: null,

        _arrDefaultRadioButton: null,
        _arrEditableRadioButton: null,

        init: function () {
            this._super(...arguments);

            this.set("objChildConfig", {
                "objChildren.status_name": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addMinLength(objMixin, 2);
                },
                "objChildren.status_description": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addMinLength(objMixin, 2);
                },
                "objChildren.status_default": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                },
                "objChildren.status_editable": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                },

                "objChildren.entry_name": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addMinLength(objMixin, 2);
                },
                "objChildren.entry_short_name": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addMinLength(objMixin, 2);
                },

                "objChildren.entry_status_name": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addMinLength(objMixin, 2);
                },
                "objChildren.entry_status_description": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addMinLength(objMixin, 2);
                }
            });

            let objRegisteredBodyViewParams = this.get("_objNavigationService.objRouteParams")["index.registration-body-view"];

            if (!a24Core.isEmpty(objRegisteredBodyViewParams)) {
                this.set("_sProfRegistrationBodyId", objRegisteredBodyViewParams.registration_body_id);
            }

            this.get("_objTitleBarService").setTitleBar(this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodies"), () => {
                this.get("_objNavigationService").navigateBack();
            });

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objSubdomainSysAdminStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: () => {
                    this.doServiceCalls();
                }
            }]);

            this.set("_arrDefaultRadioButton", this.get("_objEnumsService").getList("yesNo"));
            this.set("_arrEditableRadioButton", this.get("_objEnumsService").getList("yesNo"));

            this.set("_arrBodyHeaderActions", [{
                sTitle: this.get("_objStringsService").getString("edit"),
                sIcon: "edit",
                executeCardAction: () => {
                    this._setAddEditProfRegBodyEdit();
                }
            }]);

            this.set("_objSpecialAddEditRegBodyStatusPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: () => {
                    this._onPopupAddEditRegBodyStatusCancel();
                }
            });

            this.set("_objSpecialAddEditRegBodyEntryPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: () => {
                    this._onPopupAddEditRegBodyEntryCancel();
                }
            });

            this.set("_objSpecialAddEditRegBodyEntryStatusPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: () => {
                    this._onPopupAddEditRegBodyEntryStatusCancel();
                }
            });

            this.set("_arrBodyStatusHeaderActions", [{
                sTitle: this.get("_objStringsService").getString("add"),
                sIcon: "add",
                executeCardAction: () => {
                    this.set("_sProfRegBodyStatusHeader", this.get("_objStringsService").getTokenString("addItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodyStatuses")));
                    // Clear errors and forms
                    this.clearAllErrorMessages();
                    this.clearForm();

                    this.set("_bIsAdding", true);
                    this.set("_bAddEditRegistrationBodyStatusPopupLoading", false);
                    this.set("_bShowProfRegBodyStatusAddEdit", true);

                    Ember.run.next(() => {
                        Ember.run.schedule("afterRender", () => {
                            // Save data for unsave data comparison
                            this.set("_objOriginalData", this.getPlainFormValueObject());
                        });
                    });
                }
            }]);

            this.set("_arrEntryHeaderActions", [{
                sTitle: this.get("_objStringsService").getString("add"),
                sIcon: "add",
                executeCardAction: () => {
                    this.set("_sProfRegBodyEntryHeader", this.get("_objStringsService").getTokenString("addItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodyEntries")));
                    // Clear errors and forms
                    this.clearAllErrorMessages();
                    this.clearForm();

                    this.set("_bIsAdding", true);
                    this.set("_bAddEditRegBodyEntryPopupLoading", false);
                    this.set("_bShowProfRegBodyEntryAddEdit", true);

                    Ember.run.next(() => {
                        Ember.run.schedule("afterRender", () => {
                            // Save data for unsave data comparison
                            this.set("_objOriginalData", this.getPlainFormValueObject());
                        });
                    });
                }
            }]);

            this.set("_arrEntryStatusHeaderActions", [{
                sTitle: this.get("_objStringsService").getString("add"),
                sIcon: "add",
                executeCardAction: () => {
                    this.set("_sProfRegBodyEntryStatusHeader", this.get("_objStringsService").getTokenString("addItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodyEntriesStatuses")));
                    // Clear errors and forms
                    this.clearAllErrorMessages();
                    this.clearForm();

                    this.set("_bIsAdding", true);
                    this.set("_bAddEditRegBodyEntryStatusPopupLoading", false);
                    this.set("_bShowProfRegBodyEntryStatusAddEdit", true);

                    Ember.run.next(() => {
                        Ember.run.schedule("afterRender", () => {
                            // Save data for unsave data comparison
                            this.set("_objOriginalData", this.getPlainFormValueObject());
                        });
                    });
                }
            }]);

            this.doServiceCalls();
        },
        _setAddEditProfRegBodyEdit: function () {
            this.clearAllErrorMessages();
            this.clearForm();

            this.set("_sEditRegBodyHeaderPopup", this.get("_objStringsService").getTokenString("editItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBody")));

            this.set("_bShowProfRegBodyEdit", true);
        },
        doServiceCalls: function () {
            this.set("_bLoading", true);

            var sProfRegistrationBodyId = this.get("_sProfRegistrationBodyId");

            var objQueryParams = {
                page: { mValue: 1 },
                items_per_page: { mValue: 1000 }
            };

            var objPromisePositionTypes = this.get("_objSystemService").getPositionTypes(this, "_objSystemServicePromise", () => {}, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], () => /*sCode , sStatus, objErrorThrown, objError*/{
                this.set("_bServerError", true);
            }), {
                sortBy: {
                    mValue: "name"
                },
                items_per_page: {
                    mValue: 1000
                }
            });

            var objPromiseProfRegisteredBody = this.get("_objRestSystemRegistrationBodyService").getRegistrationBody(this, "_objProfRegisteredBodyGetPromise", sProfRegistrationBodyId, () => {}, // handled in RSVP
            a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], (sCode /*, sStatus, objErrorThrown, objError*/) => {
                if (sCode === "404") {
                    this.set("_bDisplayProfRegistrationBodyReloadCard", true);
                    return;
                }
                // Clear data
                this.set("_arrProfRegistrationBody", []);
            }), objQueryParams);

            var objPromiseProfRegisteredBodyStatuses = this.get("_objRestSystemRegistrationBodyService").getRegistrationBodyStatuses(this, "_objProfRegisteredBodyStatusesGetPromise", sProfRegistrationBodyId, () => {}, // handled in RSVP
            a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], (sCode /*, sStatus, objErrorThrown, objError*/) => {
                if (sCode === "404") {
                    this.set("_bDisplayProfRegistrationBodyStatusesReloadCard", true);
                    return;
                }
                // Clear data
                this.set("_arrProfRegistrationBodyStatuses", []);
            }), objQueryParams);

            var objPromiseProfRegisteredBodyEntries = this.get("_objRestSystemRegistrationBodyService").getRegistrationBodyEntries(this, "_objProfRegisteredBodyEntriesGetPromise", sProfRegistrationBodyId, () => {}, // handled in RSVP
            a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], (sCode /*, sStatus, objErrorThrown, objError*/) => {
                if (sCode === "404") {
                    this.set("_bDisplayProfRegistrationBodyEntriesReloadCard", true);
                    return;
                }
                // Clear data
                this.set("_arrProfRegistrationBodyEntries", []);
            }), objQueryParams);

            var objPromiseProfRegisteredBodyEntryStatuses = this.get("_objRestSystemRegistrationBodyService").getRegistrationBodyEntryStatuses(this, "_objProfRegisteredBodyEntriesStatusesGetPromise", sProfRegistrationBodyId, () => {}, // handled in RSVP
            a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], (sCode /*, sStatus, objErrorThrown, objError*/) => {
                if (sCode === "404") {
                    this.set("_bDisplayProfRegistrationBodyEntriesStatusesReloadCard", true);
                    return;
                }
                // Clear data
                this.set("_arrProfRegistrationBodyEntriesStatuses", []);
            }), objQueryParams);

            var objServiceCalls = {
                _objRSVPPositionTypesProperty: objPromisePositionTypes,
                _objRSVPProfRegisteredBodyProperty: objPromiseProfRegisteredBody,
                _objRSVPProfRegisteredBodyStatusesProperty: objPromiseProfRegisteredBodyStatuses,
                _objRSVPProfRegisteredBodyEntriesProperty: objPromiseProfRegisteredBodyEntries,
                _objRSVPProfRegisteredBodyEntryStatusesProperty: objPromiseProfRegisteredBodyEntryStatuses
            };

            var arrRequiredResponses = ["_objRSVPPositionTypesProperty", "_objRSVPProfRegisteredBodyProperty", "_objRSVPProfRegisteredBodyStatusesProperty", "_objRSVPProfRegisteredBodyEntriesProperty", "_objRSVPProfRegisteredBodyEntryStatusesProperty"];

            a24RSVP.configure(this, objServiceCalls, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: objResponses => {
                    if (!a24Core.isEmpty(objResponses._objRSVPPositionTypesProperty) && !a24Core.isEmpty(objResponses._objRSVPPositionTypesProperty.objHeaders) && objResponses._objRSVPPositionTypesProperty.objHeaders.statusText === "abort" || !a24Core.isEmpty(objResponses._objRSVPProfRegisteredBodyProperty) && !a24Core.isEmpty(objResponses._objRSVPProfRegisteredBodyProperty.objHeaders) && objResponses._objRSVPProfRegisteredBodyProperty.objHeaders.statusText === "abort" || !a24Core.isEmpty(objResponses._objRSVPProfRegisteredBodyStatusesProperty) && !a24Core.isEmpty(objResponses._objRSVPProfRegisteredBodyStatusesProperty.objHeaders) && objResponses._objRSVPProfRegisteredBodyStatusesProperty.objHeaders.statusText === "abort" || !a24Core.isEmpty(objResponses._objRSVPProfRegisteredBodyEntriesProperty) && !a24Core.isEmpty(objResponses._objRSVPProfRegisteredBodyEntriesProperty.objHeaders) && objResponses._objRSVPProfRegisteredBodyEntriesProperty.objHeaders.statusText === "abort" || !a24Core.isEmpty(objResponses._objRSVPProfRegisteredBodyEntryStatusesProperty) && !a24Core.isEmpty(objResponses._objRSVPProfRegisteredBodyEntryStatusesProperty.objHeaders) && objResponses._objRSVPProfRegisteredBodyEntryStatusesProperty.objHeaders.statusText === "abort") {
                        return;
                    }

                    if (!a24Core.isEmpty(objResponses._objRSVPPositionTypesProperty) && !a24Core.isEmpty(objResponses._objRSVPPositionTypesProperty.objResponse)) {
                        this.set("_arrPositionTypes", objResponses._objRSVPPositionTypesProperty.objResponse);
                        if (!a24Core.isEmpty(objResponses._objRSVPPositionTypesProperty.objResponse)) {
                            var arrPositionTypes = this.get("_objCandidateTypeTabsService").generateCandidatesTypes(objResponses._objRSVPPositionTypesProperty.objResponse);

                            arrPositionTypes = arrPositionTypes.map(objData => {
                                var objPositionType = {
                                    sTitle: objData.name,
                                    sValue: objData.code
                                };

                                return objPositionType;
                            });

                            this.set("_arrDisplayPositionTypes", arrPositionTypes.sortBy("sTitle"));
                        } else {
                            this.set("_arrDisplayPositionTypes", []);
                        }
                    }

                    if (!a24Core.isEmpty(objResponses._objRSVPProfRegisteredBodyProperty) && !a24Core.isEmpty(objResponses._objRSVPProfRegisteredBodyProperty.objResponse)) {
                        this._prepareDisplayBody(objResponses._objRSVPProfRegisteredBodyProperty.objResponse);
                    }

                    if (!a24Core.isEmpty(objResponses._objRSVPProfRegisteredBodyStatusesProperty) && !a24Core.isEmpty(objResponses._objRSVPProfRegisteredBodyStatusesProperty.objResponse)) {
                        this._prepareDisplayBodyStatus(objResponses._objRSVPProfRegisteredBodyStatusesProperty.objResponse);
                    }

                    if (!a24Core.isEmpty(objResponses._objRSVPProfRegisteredBodyEntriesProperty) && !a24Core.isEmpty(objResponses._objRSVPProfRegisteredBodyEntriesProperty.objResponse)) {
                        this._prepareDisplayEntry(objResponses._objRSVPProfRegisteredBodyEntriesProperty.objResponse);
                    }

                    if (!a24Core.isEmpty(objResponses._objRSVPProfRegisteredBodyEntryStatusesProperty) && !a24Core.isEmpty(objResponses._objRSVPProfRegisteredBodyEntryStatusesProperty.objResponse)) {
                        this._prepareDisplayEntryStatus(objResponses._objRSVPProfRegisteredBodyEntryStatusesProperty.objResponse);
                    }

                    this.set("_bLoading", false);
                    this.set("_bAddEditRegistrationBodyStatusPopupLoading", false);
                    this.set("_bAddEditRegBodyEntryPopupLoading", false);
                    this.set("_bAddEditRegBodyEntryStatusPopupLoading", false);
                }
            }]);
        },
        _prepareDisplayBody: function (arrResponse) {
            this.set("_arrProfRegBody", arrResponse);
            var arrPositionTypeList = [];
            var objTypeName = null;
            var arrPositionTypes = this.get("_arrPositionTypes");

            for (var i = 0; i < arrResponse.position_types.length; i++) {
                for (var j = 0; j < arrPositionTypes.length; j++) {
                    objTypeName = null;
                    if (arrResponse.position_types[i] === arrPositionTypes[j].code) {
                        objTypeName = {
                            name: arrPositionTypes[j].name,
                            code: arrPositionTypes[j].code,
                            country_code: arrPositionTypes[j].country_code
                        };
                        break;
                    }
                }
                if (!a24Core.isEmpty(objTypeName)) {
                    arrPositionTypeList.push(objTypeName);
                }
            }

            arrPositionTypeList = this.get("_objCandidateTypeTabsService").generateCandidatesTypes(arrPositionTypeList);

            var arrPositionTypeListNames = [];

            for (var l = 0; l < arrPositionTypeList.length; l++) {
                arrPositionTypeListNames.push(arrPositionTypeList[l].name);
            }

            var sStatus = this.get("_objSubdomainSysAdminEnumsService").getFrontendValue("registrationBodyStatuses", arrResponse.status);

            this.get("_objTitleBarService").setTitleBar(this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodies") + " - " + arrResponse.name, () => {
                this.get("_objNavigationService").navigateBack();
            });

            this.set("_arrProfRegistrationBody", [{
                arrItems: [{
                    sLabel: this.get("_objStringsService").getString("name"),
                    sValue: arrResponse.name,
                    bText: true
                }, {
                    sLabel: this.get("_objSubdomainSysAdminStringsService").getString("shortName"),
                    sValue: arrResponse.short_name,
                    bText: true
                }, {
                    sLabel: this.get("_objStringsService").getString("countryCode"),
                    sValue: arrResponse.country_code,
                    bText: true
                }, {
                    sLabel: this.get("_objSubdomainSysAdminStringsService").getString("positionTypes"),
                    sValue: arrPositionTypeListNames.join(", "),
                    bText: true
                }, {
                    sLabel: this.get("_objStringsService").getString("status"),
                    sValue: sStatus,
                    bText: true
                }]
            }]);
        },
        _prepareDisplayBodyStatus: function (arrResponse) {
            var objThis = this;

            var funcCardActionEditExecute = function (objItem) {
                return function () {
                    objThis._setEditStatusPopup(objItem);
                };
            };

            var arrItems = [];
            for (var i = 0; i < arrResponse.length; i++) {
                var objStatus = arrResponse[i];
                var arrActions = [{
                    sTitle: this.get("_objStringsService").getString("edit"),
                    sIcon: "edit",
                    executeCardAction: funcCardActionEditExecute(objStatus)
                }];

                var arrViewItems = [{
                    sLabel: this.get("_objStringsService").getString("name"),
                    sValue: arrResponse[i].name,
                    bText: true
                }, {
                    sLabel: this.get("_objStringsService").getString("description"),
                    sValue: arrResponse[i].description,
                    bText: true
                }, {
                    sLabel: this.get("_objSubdomainSysAdminStringsService").getString("editable"),
                    sValue: arrResponse[i].editable ? this.get("_objStringsService").getString("yes") : this.get("_objStringsService").getString("no"),
                    bText: true
                }, {
                    sLabel: this.get("_objSubdomainSysAdminStringsService").getString("default"),
                    sValue: arrResponse[i].default ? this.get("_objStringsService").getString("yes") : this.get("_objStringsService").getString("no"),
                    bText: true
                }];

                arrItems.push({
                    sLabel: arrResponse[i].name,
                    sIcon: "assignment_turned_in",
                    sExtra: arrResponse[i].default ? this.get("_objSubdomainSysAdminStringsService").getString("default") : "",
                    arrActions: arrActions,
                    arrItems: [{
                        arrItems: arrViewItems
                    }],
                    bExpanded: false
                });
            }

            this.set("_arrProfRegistrationBodyStatuses", arrItems);
        },
        _prepareDisplayEntry: function (arrResponse) {
            var objThis = this;

            var funcCardActionEditExecute = function (objItem) {
                return function () {
                    objThis._setEditEntryPopup(objItem);
                };
            };

            var arrItems = [];
            for (var i = 0; i < arrResponse.length; i++) {
                var objStatus = arrResponse[i];
                var arrActions = [{
                    sTitle: this.get("_objStringsService").getString("edit"),
                    sIcon: "edit",
                    executeCardAction: funcCardActionEditExecute(objStatus)
                }];
                var arrViewItems = [{
                    sLabel: this.get("_objStringsService").getString("name"),
                    sValue: arrResponse[i].name,
                    bText: true
                }, {
                    sLabel: this.get("_objSubdomainSysAdminStringsService").getString("shortName"),
                    sValue: arrResponse[i].short_name,
                    bText: true
                }];

                arrItems.push({
                    sLabel: arrResponse[i].name,
                    sExtra: arrResponse[i].short_name,
                    sIcon: "assignment",
                    arrActions: arrActions,
                    arrItems: [{
                        arrItems: arrViewItems
                    }],
                    bExpanded: false
                });
            }

            this.set("_arrProfRegistrationBodyEntries", arrItems);
        },
        _prepareDisplayEntryStatus: function (arrResponse) {
            var objThis = this;

            var funcCardActionEditExecute = function (objItem) {
                return function () {
                    objThis._setEditEntryStatusPopup(objItem);
                };
            };

            var arrItems = [];
            for (var i = 0; i < arrResponse.length; i++) {
                var objStatus = arrResponse[i];
                var arrActions = [{
                    sTitle: this.get("_objStringsService").getString("edit"),
                    sIcon: "edit",
                    executeCardAction: funcCardActionEditExecute(objStatus)
                }];
                var arrViewItems = [{
                    sLabel: this.get("_objStringsService").getString("name"),
                    sValue: arrResponse[i].name,
                    bText: true
                }, {
                    sLabel: this.get("_objStringsService").getString("description"),
                    sValue: arrResponse[i].description,
                    bText: true
                }];

                arrItems.push({
                    sLabel: arrResponse[i].name,
                    sIcon: "assignment_turned_in",
                    arrActions: arrActions,
                    arrItems: [{
                        arrItems: arrViewItems
                    }],
                    bExpanded: false
                });
            }

            this.set("_arrProfRegistrationBodyEntriesStatuses", arrItems);
        },
        _setRadioButtonInputValue: function (sProperty, bValue) {
            if (bValue) {
                this.set(sProperty, {
                    sValue: this.get("_objStringsService").getString("yes"),
                    sLabel: this.get("_objStringsService").getString("yes")
                });
            } else {
                this.set(sProperty, {
                    sValue: this.get("_objStringsService").getString("no"),
                    sLabel: this.get("_objStringsService").getString("no")
                });
            }
        },
        _setEditStatusPopup: function (objItem) {
            this.set("_sProfRegBodyStatusHeader", this.get("_objStringsService").getTokenString("editItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodyStatuses")));

            // Clear errors and forms
            this.clearAllErrorMessages();
            this.clearForm();

            if (!a24Core.isEmpty(objItem.name)) {
                this.get("objChildren.status_name").setValueFromOutside(objItem.name);
            }

            if (!a24Core.isEmpty(objItem.description)) {
                this.get("objChildren.status_description").setValueFromOutside(objItem.description);
            }

            this._setRadioButtonInputValue("_objDefaultSelected", objItem.default);
            this._setRadioButtonInputValue("_objEditableSelected", objItem.editable);

            this.set("_bIsAdding", false);
            this.set("_bAddEditRegistrationBodyStatusPopupLoading", false);
            this.set("_bShowProfRegBodyStatusAddEdit", true);
            this.set("_sRegBodyStatusId", objItem._id);

            Ember.run.next(() => {
                Ember.run.schedule("afterRender", () => {
                    // Save data for unsave data comparison
                    this.set("_objOriginalData", this.getPlainFormValueObject());
                });
            });
        },
        _setEditEntryPopup: function (objItem) {
            this.set("_sProfRegBodyEntryHeader", this.get("_objStringsService").getTokenString("editItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodyEntries")));

            // Clear errors and forms
            this.clearAllErrorMessages();
            this.clearForm();

            if (!a24Core.isEmpty(objItem.name)) {
                this.get("objChildren.entry_name").setValueFromOutside(objItem.name);
            }

            if (!a24Core.isEmpty(objItem.short_name)) {
                this.get("objChildren.entry_short_name").setValueFromOutside(objItem.short_name);
            }

            this.set("_bIsAdding", false);
            this.set("_bAddEditRegBodyEntryPopupLoading", false);
            this.set("_bShowProfRegBodyEntryAddEdit", true);
            this.set("_sRegBodyEntryId", objItem._id);

            Ember.run.next(() => {
                Ember.run.schedule("afterRender", () => {
                    // Save data for unsave data comparison
                    this.set("_objOriginalData", this.getPlainFormValueObject());
                });
            });
        },
        _setEditEntryStatusPopup: function (objItem) {
            this.set("_sProfRegBodyEntryStatusHeader", this.get("_objStringsService").getTokenString("editItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodyEntriesStatuses")));

            // Clear errors and forms
            this.clearAllErrorMessages();
            this.clearForm();

            if (!a24Core.isEmpty(objItem.name)) {
                this.get("objChildren.entry_status_name").setValueFromOutside(objItem.name);
            }

            if (!a24Core.isEmpty(objItem.description)) {
                this.get("objChildren.entry_status_description").setValueFromOutside(objItem.description);
            }

            this.set("_bIsAdding", false);
            this.set("_bAddEditRegBodyEntryStatusPopupLoading", false);
            this.set("_bShowProfRegBodyEntryStatusAddEdit", true);
            this.set("_sRegBodyEntryStatusId", objItem._id);

            Ember.run.next(() => {
                Ember.run.schedule("afterRender", () => {
                    // Save data for unsave data comparison
                    this.set("_objOriginalData", this.getPlainFormValueObject());
                });
            });
        },
        doProfRegBodiesUpdated: function () {
            this.set("_bShowProfRegBodyEdit", false);
            this.set("_bShowProfRegBodyStatusAddEdit", false);
            this.set("_bShowProfRegBodyEntryAddEdit", false);
            this.set("_bShowProfRegBodyEntryStatusAddEdit", false);

            this.doServiceCalls();
        },
        _onPopupAddEditRegBodyStatusCancel: function () {
            if (this.get("_bAddEditRegistrationBodyStatusPopupLoading")) {
                return;
            }

            var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

            if (bDataMatches) {
                this.set("_bShowProfRegBodyStatusAddEdit", false);
            } else {
                this.set("_bShowUnsavedData", true);
            }
        },
        _onPopupAddEditRegBodyEntryCancel: function () {
            if (this.get("_bAddEditRegBodyEntryPopupLoading")) {
                return;
            }

            var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

            if (bDataMatches) {
                this.set("_bShowProfRegBodyEntryAddEdit", false);
            } else {
                this.set("_bShowUnsavedData", true);
            }
        },
        _onPopupAddEditRegBodyEntryStatusCancel: function () {
            if (this.get("_bAddEditRegBodyEntryStatusPopupLoading")) {
                return;
            }

            var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

            if (bDataMatches) {
                this.set("_bShowProfRegBodyEntryStatusAddEdit", false);
            } else {
                this.set("_bShowUnsavedData", true);
            }
        },

        _getPayload: function () {
            var objData = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());

            if (!a24Core.isEmpty(objData.status_name)) {
                objData.name = objData.status_name;
                objData.description = objData.status_description;
                objData.default = objData.status_default === this.get("_objStringsService").getString("yes");
                objData.editable = objData.status_editable === this.get("_objStringsService").getString("yes");

                delete objData.status_name;
                delete objData.status_description;
                delete objData.status_default;
                delete objData.status_editable;
            }

            if (!a24Core.isEmpty(objData.entry_name)) {
                objData.name = objData.entry_name;
                objData.short_name = objData.entry_short_name;

                delete objData.entry_name;
                delete objData.entry_short_name;
            }

            if (!a24Core.isEmpty(objData.entry_status_name)) {
                objData.name = objData.entry_status_name;
                objData.description = objData.entry_status_description;

                delete objData.entry_status_name;
                delete objData.entry_status_description;
            }

            return objData;
        },

        _setSuccessResponse: function (sSnackbarMessage) {
            this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);

            this.doProfRegBodiesUpdated();
        },

        _setErrorResponse: function (sSnackbarMessage) {
            this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);

            this.set("_bAddQualificationPopupLoading", false);
        },

        _createRegBodyStatus: function () {
            this.set("_bAddEditRegistrationBodyStatusPopupLoading", true);

            var objData = this._getPayload();

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodyStatuses")));

            this.get("_objRestSystemRegistrationBodyService").createRegistrationBodyStatuses(this, "_objRegBodyStatusCreatePromise", this.get("_sProfRegistrationBodyId"), objData, () => {
                this._setSuccessResponse(this.get("_objStringsService").getTokenString("successAddItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodyStatuses")));
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], () => /* sCode, sStatus, objErrorThrown, objError */{
                this._setErrorResponse(this.get("_objStringsService").getTokenString("failAddItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodyStatuses")));

                this.set("_bAddEditRegistrationBodyStatusPopupLoading", false);
            }));
        },
        _updateRegBodyStatus: function () {
            this.set("_bAddEditRegistrationBodyStatusPopupLoading", true);

            var objData = this._getPayload();

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodyStatuses")));

            this.get("_objRestSystemRegistrationBodyService").updateRegistrationBodyStatuses(this, "_objRegBodyStatusCreatePromise", this.get("_sProfRegistrationBodyId"), this.get("_sRegBodyStatusId"), objData, () => {
                this._setSuccessResponse(this.get("_objStringsService").getTokenString("successUpdateItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodyStatuses")));
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], () => /* sCode, sStatus, objErrorThrown, objError */{
                this._setErrorResponse(this.get("_objStringsService").getTokenString("failUpdateItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodyStatuses")));

                this.set("_bAddEditRegistrationBodyStatusPopupLoading", false);
            }));
        },

        _createRegBodyEntry: function () {
            this.set("_bAddEditRegBodyEntryPopupLoading", true);

            var objData = this._getPayload();

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodyEntries")));

            this.get("_objRestSystemRegistrationBodyService").createRegistrationBodyEntries(this, "_objRegBodyStatusCreatePromise", this.get("_sProfRegistrationBodyId"), objData, () => {
                this._setSuccessResponse(this.get("_objStringsService").getTokenString("successAddItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodyEntries")));
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], () => /* sCode, sStatus, objErrorThrown, objError */{
                this._setErrorResponse(this.get("_objStringsService").getTokenString("failAddItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodyEntries")));

                this.set("_bAddEditRegBodyEntryPopupLoading", false);
            }));
        },
        _updateRegBodyEntry: function () {
            this.set("_bAddEditRegBodyEntryPopupLoading", true);

            var objData = this._getPayload();

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodyEntries")));

            this.get("_objRestSystemRegistrationBodyService").updateRegistrationBodyEntries(this, "_objRegBodyStatusCreatePromise", this.get("_sProfRegistrationBodyId"), this.get("_sRegBodyEntryId"), objData, () => {
                this._setSuccessResponse(this.get("_objStringsService").getTokenString("successUpdateItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodyEntries")));
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], () => /* sCode, sStatus, objErrorThrown, objErro */{
                this._setErrorResponse(this.get("_objStringsService").getTokenString("failUpdateItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodyEntries")));

                this.set("_bAddEditRegBodyEntryPopupLoading", false);
            }));
        },

        _createRegBodyEntryStatus: function () {
            this.set("_bAddEditRegBodyEntryStatusPopupLoading", true);

            var objData = this._getPayload();

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodyEntriesStatuses")));

            this.get("_objRestSystemRegistrationBodyService").createRegistrationBodyEntryStatus(this, "_objRegBodyStatusCreatePromise", this.get("_sProfRegistrationBodyId"), objData, () => {
                this._setSuccessResponse(this.get("_objStringsService").getTokenString("successAddItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodyEntriesStatuses")));
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], () => /* sCode, sStatus, objErrorThrown, objError */{
                this._setErrorResponse(this.get("_objStringsService").getTokenString("failAddItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodyEntriesStatuses")));

                this.set("_bAddEditRegBodyEntryStatusPopupLoading", false);
            }));
        },
        _updateRegBodyEntryStatus: function () {
            this.set("_bAddEditRegBodyEntryStatusPopupLoading", true);

            var objData = this._getPayload();

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodyEntriesStatuses")));

            this.get("_objRestSystemRegistrationBodyService").updateRegistrationBodyEntryStatus(this, "_objRegBodyStatusCreatePromise", this.get("_sProfRegistrationBodyId"), this.get("_sRegBodyEntryStatusId"), objData, () => {
                this._setSuccessResponse(this.get("_objStringsService").getTokenString("successUpdateItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodyEntriesStatuses")));
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], () => /* sCode, sStatus, objErrorThrown, objError */{
                this._setErrorResponse(this.get("_objStringsService").getTokenString("failUpdateItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBodyEntriesStatuses")));

                this.set("_bAddEditRegBodyEntryStatusPopupLoading", false);
            }));
        },

        actions: {
            onUnsavedConfirm: function () {
                this.set("_bShowUnsavedData", false);
                this.set("_bShowProfRegBodyStatusAddEdit", false);
                this.set("_bShowProfRegBodyEntryAddEdit", false);
                this.set("_bShowProfRegBodyEntryStatusAddEdit", false);
            },
            onProfRegEditPopupClose: function () {
                this.set("_bShowProfRegBodyStatusAddEdit", false);
            },
            onProfRegistrationBodiesAddedUpdatedAction: function () {
                this.doProfRegBodiesUpdated();
            },
            onPopupAddEditRegBodyStatusCancel: function () {
                this._onPopupAddEditRegBodyStatusCancel();
            },
            onPopupAddEditRegBodyStatusConfirm: function () {
                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    if (this.get("_bIsAdding")) {
                        this._createRegBodyStatus();
                    } else {
                        this._updateRegBodyStatus();
                    }
                }
            },
            onPopupAddEditRegBodyEntryCancel: function () {
                this._onPopupAddEditRegBodyEntryCancel();
            },
            onPopupAddEditRegBodyEntryConfirm: function () {
                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    if (this.get("_bIsAdding")) {
                        this._createRegBodyEntry();
                    } else {
                        this._updateRegBodyEntry();
                    }
                }
            },
            onPopupAddEditRegBodyEntryStatusCancel: function () {
                this._onPopupAddEditRegBodyEntryStatusCancel();
            },
            onPopupAddEditRegBodyEntryStatusConfirm: function () {
                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    if (this.get("_bIsAdding")) {
                        this._createRegBodyEntryStatus();
                    } else {
                        this._updateRegBodyEntryStatus();
                    }
                }
            }
        }
    });
});