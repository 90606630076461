define("a24-staffshift-subdomain-sysadmin/services/rest-chat-conversations", ["exports", "a24-ember-staffshift-core/services/rest-chat-conversations"], function (exports, _restChatConversations) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _restChatConversations.default;
    }
  });
});