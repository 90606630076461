define("a24-staffshift-subdomain-sysadmin/helpers/array-filter", ["exports", "a24-ember-lib/helpers/array-filter"], function (exports, _arrayFilter) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _arrayFilter.default;
    }
  });
  Object.defineProperty(exports, "arrayFilter", {
    enumerable: true,
    get: function () {
      return _arrayFilter.arrayFilter;
    }
  });
});