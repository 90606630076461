define("a24-staffshift-subdomain-sysadmin/components/email-preview", ["exports", "a24-ember-staffshift-core/components/email-preview"], function (exports, _emailPreview) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _emailPreview.default;
    }
  });
});