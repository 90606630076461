define("a24-staffshift-subdomain-sysadmin/components/stepper-wrapper", ["exports", "a24-ember-lib/components/stepper-wrapper"], function (exports, _stepperWrapper) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _stepperWrapper.default;
    }
  });
});