define("a24-staffshift-subdomain-sysadmin/services/rest-entity-action-shift", ["exports", "a24-ember-staffshift-core/services/rest-entity-action-shift"], function (exports, _restEntityActionShift) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _restEntityActionShift.default;
    }
  });
});