define("a24-staffshift-subdomain-sysadmin/services/a24-strings-subdomain-sysadmin", ["exports", "a24-ember-lookup/services/a24-strings-base"], function (exports, _a24StringsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _a24StringsBase.default.extend({
        _objStrings: null,
        init: function () {
            this._super(...arguments);

            this.set("_objStrings", {

                //===== STRINGS START =====

                // Temp until pages are implemented
                policies: "Policies",
                skill: "Skill",
                skills: "Skills",
                jobFamilies: "Job Families",
                jobTitles: "Job Titles",
                positionType: "Position Type",
                loadDataRetry: "Could not load all the required data for this page, please click the refresh button to try again",
                refresh: "Refresh",
                reload: "Reload",
                qualification: "Qualification",
                qualifications: "Qualifications",
                professionalRegisteredBodies: "Professional Registered Bodies",
                professionalRegisteredBody: "Professional Registered Body",
                professionalRegisteredBodyStatuses: "Professional Registered Body Statuses",
                professionalRegisteredBodyEntries: "Professional Registered Body Entries",
                professionalRegisteredBodyEntriesStatuses: "Professional Registered Body Entry Statuses",
                shortName: "Short Name",
                positionTypes: "Position Types",
                editable: "Editable",
                default: "Default",
                activityType: "Activity Type",
                activityTypes: "Activity Types",
                icon: "Icon",
                code: "Code",
                order: "Order",
                toAddAnIconName: "To add an icon name",
                clickHere: "click here",
                context: "Context",
                agency: "Agency",
                agencyGroup: "Agency Group",
                socialLinkTypes: "Social Link Types",
                socialLinkType: "Social Link Type",
                id: "Id",
                subjectType: "Subject Type",
                validationAgencyGroupClientSubjectType: "Client subject type cannot be used with Agency Group context."
                //===== STRINGS END =====

                //===== TOKEN STRINGS START =====
                //===== TOKEN STRINGS END   =====

            });
        }
    });
});