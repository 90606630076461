define("a24-staffshift-subdomain-sysadmin/components/page-activity-types", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-sysadmin/config/environment", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _environment, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        classNames: ["page-activity-types"],
        objQueryParams: null,
        _arrContexts: null,
        _sUrlBase: null,
        _sIconName: null,
        _bIsAdd: false,
        _bPageLoading: false,
        _bActivityTypeDisabled: false,
        _sActivityTypeDescription: null,
        _objSelectedActivityType: null,
        _objSelectedSubjectType: null,
        _objActivityTypeUpdatePromise: null,
        _objActivityTypeCreatePromise: null,
        _objOriginalData: null,
        _sAddEditActivityTypeHeaderPopup: null,
        _objSpecialAddEditActivityTypePopupAction: null,
        _bShowAddEditActivityTypePopup: false,
        _bAddActivityTypePopupLoading: false,
        _bShowAddEditActivityTypeUnsavedData: false,
        _objRestSystemActivityTypeService: Ember.inject.service("rest-system-activity-type"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSubdomainSysAdminStringsService: Ember.inject.service("a24-strings-subdomain-sysadmin"),
        _objSubdomainSysAdminEnumsService: Ember.inject.service("a24-enums-subdomain-sysadmin"),

        _objFabButtonService: Ember.inject.service("fab-button"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objSSCoreEnumsService: Ember.inject.service("a24-enums-staffshift-core"),
        _objSSCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _arrSubjectTypesDropdown: null,
        _arrSubjectTypesDropdownFull: null,
        init: function () {
            this._super(...arguments);

            this.set("_sUrlBase", _environment.default.sRestApiBaseUrl + "/v1/system/activity-type");

            this.set("_objSpecialAddEditActivityTypePopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: () => {
                    this._onPopupAddEditActivityTypeCancel();
                }
            });

            this.set("_arrContexts", this.get("_objSubdomainSysAdminEnumsService").getList("contexts"));

            this.get("_objTitleBarService").setTitleBar(this.get("_objSubdomainSysAdminStringsService").getString("activityTypes"));

            this.set("objChildConfig", {
                "objChildren.name": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addMinLength(objMixin, 1);
                },
                "objChildren.code": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addMinLength(objMixin, 1);
                },
                "objChildren.order": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addWholeNumber(objMixin);
                },
                "objChildren.context": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                },
                "objChildren.subject_type": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                }
            });

            var arrSubjectTypes = this.get("_objSSCoreEnumsService").getList("activitySubjectTypes");
            var arrSubjectTypesDropdown = [];
            for (var k = 0; k < arrSubjectTypes.length; k++) {
                arrSubjectTypesDropdown.push({
                    sTitle: arrSubjectTypes[k].sLabel,
                    sValue: arrSubjectTypes[k].sValue
                });
            }

            this.set("_arrSubjectTypesDropdown", arrSubjectTypesDropdown);
            this.set("_arrSubjectTypesDropdownFull", arrSubjectTypesDropdown);

            this._setupDatagridConfig();
        },

        onInputValueChanged: function (arrChildRef) {
            this._super(...arguments);

            // Get the reference of the field that changed
            var sLookup = arrChildRef.join(".");

            if (sLookup === "objChildren.icon") {
                this.set("_sIconName", this.get("objChildren.icon.mValue"));
            }

            if (sLookup === "objChildren.context") {
                if (this.get(sLookup + ".mValue.sValue") === this.get("_objSSCoreConstants.CONTEXT_AGENCY_GROUP_LOWER_HYPHEN")) {
                    let arrSubjectTypesDropdown = Ember.copy(this.get("_arrSubjectTypesDropdownFull"), true);
                    let objSubjectType = arrSubjectTypesDropdown.findBy('sValue', this.get("_objSSCoreEnumsService.activitySubjectTypes.CLIENT.backend"));
                    arrSubjectTypesDropdown.removeObject(objSubjectType);
                    this.set("_arrSubjectTypesDropdown", arrSubjectTypesDropdown);
                } else {
                    this.set("_arrSubjectTypesDropdown", this.get("_arrSubjectTypesDropdownFull"));
                }
            }
        },

        _setupDatagridConfig: function () {
            var objThis = this;

            this.get("_objFabButtonService").showFabButton(() => {
                this._setAddEditPopup();
            });

            var arrDataGridHeaderConfig = [{
                iUniqueColNum: 1,
                sTitle: this.get("_objStringsService").getString("name"),
                sProperty: "name",
                sFilterType: "text",
                bText: true,
                iWidth: 25,
                bHideEmptyCheck: true
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objSubdomainSysAdminStringsService").getString("icon"),
                sProperty: "icon",
                sFilterType: "text",
                iWidth: 5,
                bCustom: true,
                bStopFiltering: true,
                bStopColumnSort: true,
                funcTableCustomVal: function (objHeader, objRowData) {
                    return Ember.String.htmlSafe(`<i class="material-icons font-lg">${objRowData[objHeader.sProperty]}</i>`);
                }
            }, {
                iUniqueColNum: 3,
                sTitle: this.get("_objSubdomainSysAdminStringsService").getString("code"),
                sProperty: "code",
                sFilterType: "text",
                iWidth: 20,
                bText: true,
                bHideEmptyCheck: true
            }, {
                iUniqueColNum: 4,
                sTitle: this.get("_objSubdomainSysAdminStringsService").getString("context"),
                sProperty: "context",
                sFilterType: "dropdown",
                arrMappingData: this.get("_arrContexts").map(objContext => {
                    return {
                        sTitle: objContext.sLabel,
                        sValue: objContext.sValue
                    };
                }),
                bMapping: true,
                bStopColumnSort: true,
                bHideEmptyCheck: true,
                iWidth: 35
            }, {
                iUniqueColNum: 5,
                sTitle: this.get("_objStringsService").getString("disabled"),
                sProperty: "disabled",
                sFilterType: "bool",
                bStopColumnSort: true,
                bHideEmptyCheck: true,
                bBool: true,
                bMapping: true,
                iWidth: 5,
                arrMappingData: [{
                    sValue: "true",
                    sTitle: this.get("_objStringsService").getString("yes")
                }, {
                    sValue: "false",
                    sTitle: this.get("_objStringsService").getString("no")
                }]
            }, {
                iUniqueColNum: 7,
                sTitle: this.get("_objSubdomainSysAdminStringsService").getString("subjectType"),
                sProperty: "subject_type",
                sFilterType: "dropdown",
                iWidth: 8,
                bMapping: true,
                arrMappingData: this.get("_arrSubjectTypesDropdown"),
                bStopColumnSort: true
            }, {
                iUniqueColNum: 6,
                sTitle: this.get("_objSubdomainSysAdminStringsService").getString("order"),
                sProperty: "order",
                sFilterType: "text",
                bStopFiltering: true,
                bText: true,
                iWidth: 5
            }];

            var arrSingleItemActions = [{
                sTitle: this.get("_objStringsService").getString("edit"),
                sIcon: "edit",
                executeCardAction: function () {
                    objThis._setAddEditPopup(objThis.get("_objSelectedActivityType"));
                }
            }];

            this.set("_objDatagridConfig", {
                sDatagridHeader: this.get("_objSubdomainSysAdminStringsService").getString("activityTypes"),
                iMaxHeight: 40 * 27,
                bSelectColumn: true,
                bEmitSelectRowEvent: true,
                bHasReloadButton: true,
                arrSingleItemSelectActions: arrSingleItemActions,
                arrHeaderConfig: arrDataGridHeaderConfig
            });
        },

        _onPopupAddEditActivityTypeCancel: function () {
            if (this.get("_bAddActivityTypePopupLoading")) {
                return;
            }

            var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

            if (bDataMatches) {
                this.set("_bShowAddEditActivityTypePopup", false);
                this.set("_bActivityTypeDisabled", false);
            } else {
                this.set("_bShowAddEditActivityTypeUnsavedData", true);
            }
        },

        _setAddEditPopup: function (objActivityType) {
            this.clearAllErrorMessages();
            this.clearForm();

            if (!a24Core.isEmpty(objActivityType)) {
                this.set("_bIsAdd", false);

                this.set("_sAddEditActivityTypeHeaderPopup", this.get("_objStringsService").getTokenString("editItem", this.get("_objSubdomainSysAdminStringsService").getString("activityType")));

                this._populateInputFields(objActivityType);
            } else {
                this.set("_bIsAdd", true);

                this.set("_sAddEditActivityTypeHeaderPopup", this.get("_objStringsService").getTokenString("addItem", this.get("_objSubdomainSysAdminStringsService").getString("activityType")));
            }

            this.set("_bAddActivityTypePopupLoading", false);
            this.set("_bShowAddEditActivityTypePopup", true);

            Ember.run.next(() => {
                Ember.run.schedule("afterRender", () => {
                    this.set("_objOriginalData", this.getPlainFormValueObject());
                });
            });
        },

        _populateInputFields: function (objActivityType) {
            if (!a24Core.isEmpty(objActivityType.name)) {
                this.get("objChildren.name").setValueFromOutside(objActivityType.name);
            }
            if (!a24Core.isEmpty(objActivityType.code)) {
                this.get("objChildren.code").setValueFromOutside(objActivityType.code);
            }
            if (!a24Core.isEmpty(objActivityType.order)) {
                this.get("objChildren.order").setValueFromOutside(objActivityType.order);
            }
            if (!a24Core.isEmpty(objActivityType.icon)) {
                this.get("objChildren.icon").setValueFromOutside(objActivityType.icon);
            }
            this.set("_bActivityTypeDisabled", objActivityType.disabled === true);
            if (!a24Core.isEmpty(objActivityType.description)) {
                this.get("objChildren.description").setValueFromOutside(objActivityType.description);
            }
        },

        _getPayload: function () {
            var objData = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());
            if (!a24Core.isEmpty(objData.order)) {
                objData.order = parseInt(objData.order);
            }
            if (!this.get("_bIsAdd")) {
                objData.context = this.get("_objSelectedActivityType.context");
            }
            return objData;
        },

        _createActivityType: function () {
            var objData = this._getPayload();

            this.set("_bAddActivityTypePopupLoading", true);
            this.set("_arrSubjectTypesDropdown", this.get("_arrSubjectTypesDropdownFull"));
            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objSubdomainSysAdminStringsService").getString("activityType")));

            this.get("_objRestSystemActivityTypeService").createActivityType(this, "_objActivityTypeCreatePromise", objData, () => {
                this._setSuccessResponse(this.get("_objStringsService").getTokenString("successAddItem", this.get("_objSubdomainSysAdminStringsService").getString("activityType")));
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], (sCode, sStatus, objErrorThrown, objError) => {
                this._setErrorResponse(sCode, objError, this.get("_objStringsService").getTokenString("failAddItem", this.get("_objSubdomainSysAdminStringsService").getString("activityType")));
            }));
        },

        _updateActivityType: function () {
            this.set("_bAddActivityTypePopupLoading", true);

            var objData = this._getPayload();

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objSubdomainSysAdminStringsService").getString("activityType")));

            this.get("_objRestSystemActivityTypeService").updateActivityType(this, "_objActivityTypeUpdatePromise", this.get("_objSelectedActivityType._id"), objData, () => {
                this._setSuccessResponse(this.get("_objStringsService").getTokenString("successUpdateItem", this.get("_objSubdomainSysAdminStringsService").getString("activityType")));
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], (sCode, sStatus, objErrorThrown, objError) => {
                this._setErrorResponse(sCode, objError, this.get("_objStringsService").getTokenString("failUpdateItem", this.get("_objSubdomainSysAdminStringsService").getString("activityType")));
            }));
        },

        _setErrorResponse: function (sCode, objError, sSnackbarMessage) {
            if (sCode === "MIN_LENGTH") {
                this.setResponseError(objError.arrPath, "validationRestMinLength");
            } else if (sCode === "PATTERN" || sCode === "INVALID_FORMAT" || sCode === "INVALID_TYPE") {
                this.setResponseError(objError.arrPath, "validationRestInvalidFormat");
            } else {
                this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            }
            this.set("_bAddActivityTypePopupLoading", false);
        },

        _setSuccessResponse: function (sSnackbarMessage) {
            this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            this.set("_bShowAddEditActivityTypePopup", false);
            this.set("_bActivityTypeDisabled", false);
            this._refreshActivityTypes();
        },

        _refreshActivityTypes: function () {
            // Put the page in loading
            this.set("_bPageLoading", true);

            Ember.run.next(() => {
                Ember.run.schedule("afterRender", () => {
                    // Take page out of loading
                    this.set("_bPageLoading", false);
                });
            });
        },

        _onItemsSelect: function (arrSelectedItems) {
            if (!a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length === 1) {
                this.set("_objSelectedActivityType", arrSelectedItems[0]);
            } else {
                this.set("_objSelectedActivityType", null);
            }
        },

        actions: {
            cardDatagridTableNoneSelected: function () {
                this._onItemsSelect(null);
            },

            cardDatagridTableOneSelected: function (arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },

            cardDatagridTableMultiSelected: function (arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },

            onAddEditActivityTypePopupUnsavedConfirm: function () {
                this.set("_bShowAddEditActivityTypeUnsavedData", false);
                this.set("_bShowAddEditActivityTypePopup", false);
                this.set("_bActivityTypeDisabled", false);
            },

            onPopupAddEditActivityTypeCancel: function () {
                this._onPopupAddEditActivityTypeCancel();
            },

            onPopupAddEditActivityTypeConfirm: function () {
                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    if (this.get("_bIsAdd")) {
                        this._createActivityType();
                    } else {
                        this._updateActivityType();
                    }
                }
            }
        }
    });
});