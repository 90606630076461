define("a24-staffshift-subdomain-sysadmin/services/tooltip", ["exports", "a24-ember-lib/services/tooltip"], function (exports, _tooltip) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _tooltip.default;
    }
  });
});