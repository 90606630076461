define("a24-staffshift-subdomain-sysadmin/components/page-skills", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-sysadmin/config/environment", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _environment, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        objQueryParams: null,
        _sUrlBase: null,
        _bIsAdd: false,
        _bPageLoading: false,
        _bShowLoadFailedPopup: false,
        _objSelectedSkill: null,
        _objPositionTypesResponse: null,
        _objSkillUpdatePromise: null,
        _objSkillCreatePromise: null,
        _objOriginalData: null,
        _sAddEditSkillHeaderPopup: null,
        _objSpecialAddEditSkillPopupAction: null,
        _arrDisplayPositionTypes: null,
        _arrSelectedPositionTypes: null,
        _arrStatuses: null,
        _objSelectedStatus: null,
        _sSkillDescription: null,
        _bShowAddEditSkillPopup: false,
        _bAddSkillPopupLoading: false,
        _bShowAddEditSkillUnsavedData: false,
        _objRestSystemSkillsService: Ember.inject.service("rest-system-skills"),
        _objRestSystem: Ember.inject.service("rest-system"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSubdomainSysAdminEnumsService: Ember.inject.service("a24-enums-subdomain-sysadmin"),
        _objSubdomainSysAdminStringsService: Ember.inject.service("a24-strings-subdomain-sysadmin"),
        _objCandidateTypeTabsService: Ember.inject.service("candidate-type-tabs"),
        _objFabButtonService: Ember.inject.service("fab-button"),
        _objSnackbarService: Ember.inject.service("snackbar"),

        init: function () {
            this._super(...arguments);

            this.set("_sUrlBase", _environment.default.sRestApiBaseUrl + "/v1/system/skills");

            this.set("_arrStatuses", this.get("_objSubdomainSysAdminEnumsService").getList("skillStatuses"));

            this.set("_objSpecialAddEditSkillPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: () => {
                    this._onPopupAddEditSkillCancel();
                }
            });

            this.get("_objTitleBarService").setTitleBar(this.get("_objSubdomainSysAdminStringsService").getString("skills"));

            this.set("objChildConfig", {
                "objChildren.name": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                },
                "objChildren.status": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                }
            });

            this._doServiceCalls();
        },

        _doServiceCalls: function () {
            this.set("_bPageLoading", true);

            this.get("_objRestSystem").getPositionTypes(this, "_objPositionTypesResponse", arrData => {
                if (!a24Core.isEmpty(arrData)) {
                    var arrPositionTypes = this.get("_objCandidateTypeTabsService").generateCandidatesTypes(arrData);

                    arrPositionTypes = arrPositionTypes.map(objData => {
                        var objPositionType = {
                            sTitle: objData.name,
                            sValue: objData.code
                        };
                        return objPositionType;
                    });

                    this.set("_arrDisplayPositionTypes", arrPositionTypes.sortBy("sTitle"));
                } else {
                    this.set("_arrDisplayPositionTypes", []);
                }

                this._setupDatagridConfig();

                this.set("_bPageLoading", false);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], () => /*sCode, sStatus, objErrorThrown, objError*/{
                this.set("_bPageLoading", false);
                this.set("_bShowLoadFailedPopup", true);
            }));
        },

        _setupDatagridConfig: function () {
            var objThis = this;

            this.get("_objFabButtonService").showFabButton(() => {
                this._setAddEditPopup();
            });

            var arrPositionTypesDropdown = this.get("_arrDisplayPositionTypes");

            var arrStatusesDropdown = this.get("_arrStatuses").map(objStatus => {
                return {
                    sTitle: objStatus.sLabel,
                    sValue: objStatus.sValue
                };
            });

            var arrDataGridHeaderConfig = [{
                iUniqueColNum: 1,
                sTitle: this.get("_objSubdomainSysAdminStringsService").getString("skill"),
                sProperty: "name",
                sFilterType: "text",
                bText: true,
                iWidth: 35,
                bHideEmptyCheck: true
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objSubdomainSysAdminStringsService").getString("positionType"),
                sProperty: "position_types",
                sFilterType: "dropdownMulti",
                arrMappingData: arrPositionTypesDropdown,
                bMapping: true,
                iWidth: 35,
                bCustom: true,
                bHideEmptyCheck: true,
                bStopColumnSort: true,
                funcTableCustomVal: function (objHeader, objRowData) {
                    var arrPositionTypesName = [];
                    var arrPositionTypes = objRowData[objHeader.sProperty];

                    if (!a24Core.isEmpty(arrPositionTypes)) {
                        for (var i = 0; i < arrPositionTypes.length; i++) {
                            var objPositionType = arrPositionTypesDropdown.findBy("sValue", arrPositionTypes[i]);
                            if (!a24Core.isEmpty(objPositionType)) {
                                arrPositionTypesName.push(objPositionType.sTitle);
                            }
                        }
                    }

                    return arrPositionTypesName.join(", ");
                }
            }, {
                iUniqueColNum: 3,
                sTitle: this.get("_objStringsService").getString("status"),
                sProperty: "status",
                sFilterType: "dropdown",
                arrMappingData: arrStatusesDropdown,
                bHideEmptyCheck: true,
                bStopColumnSort: true,
                bMapping: true,
                iWidth: 5
            }, {
                iUniqueColNum: 4,
                sTitle: this.get("_objStringsService").getString("description"),
                sProperty: "description",
                sFilterType: "text",
                bStopFiltering: true,
                bStopColumnSort: true,
                bText: true,
                iWidth: 25
            }];

            var arrSingleItemActions = [{
                sTitle: this.get("_objStringsService").getString("edit"),
                sIcon: "edit",
                executeCardAction: function () {
                    objThis._setAddEditPopup(objThis.get("_objSelectedSkill"));
                }
            }];

            this.set("_objDatagridConfig", {
                sDatagridHeader: this.get("_objSubdomainSysAdminStringsService").getString("skills"),
                iMaxHeight: 40 * 27,
                bSelectColumn: true,
                bEmitSelectRowEvent: true,
                bHasReloadButton: true,
                arrSingleItemSelectActions: arrSingleItemActions,
                arrHeaderConfig: arrDataGridHeaderConfig
            });
        },

        _onPopupAddEditSkillCancel: function () {
            if (this.get("_bAddSkillPopupLoading")) {
                return;
            }

            var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

            if (bDataMatches) {
                this.set("_bShowAddEditSkillPopup", false);
            } else {
                this.set("_bShowAddEditSkillUnsavedData", true);
            }
        },

        _setAddEditPopup: function (objSkill) {
            this.clearAllErrorMessages();
            this.clearForm();

            if (!a24Core.isEmpty(objSkill)) {
                this.set("_bIsAdd", false);

                this.set("_sAddEditSkillHeaderPopup", this.get("_objStringsService").getTokenString("editItem", this.get("_objSubdomainSysAdminStringsService").getString("skill")));

                this._populateInputFields(objSkill);
            } else {
                this.set("_bIsAdd", true);

                this.set("_sAddEditSkillHeaderPopup", this.get("_objStringsService").getTokenString("addItem", this.get("_objSubdomainSysAdminStringsService").getString("skill")));
            }

            this.set("_bAddSkillPopupLoading", false);
            this.set("_bShowAddEditSkillPopup", true);

            Ember.run.next(() => {
                Ember.run.schedule("afterRender", () => {
                    this.set("_objOriginalData", this.getPlainFormValueObject());
                });
            });
        },

        _populateInputFields: function (objSkill) {
            if (!a24Core.isEmpty(objSkill.name)) {
                this.get("objChildren.name").setValueFromOutside(objSkill.name);
            }

            var arrSelectedPositionTypes = [];

            for (var i = 0; i < objSkill.position_types.length; i++) {
                var objPositionType = this.get("_arrDisplayPositionTypes").findBy("sValue", objSkill.position_types[i]);
                if (!a24Core.isEmpty(objPositionType)) {
                    arrSelectedPositionTypes.push(objPositionType);
                }
            }

            if (!a24Core.isEmpty(arrSelectedPositionTypes)) {
                this.set("_arrSelectedPositionTypes", Ember.copy(arrSelectedPositionTypes, true));
            }

            var objSelectedStatus = this.get("_arrStatuses").findBy("sValue", objSkill.status);

            if (!a24Core.isEmpty(objSelectedStatus)) {
                this.set("_objSelectedStatus", Ember.copy(objSelectedStatus, true));
            }

            if (!a24Core.isEmpty(objSkill.description)) {
                this.get("objChildren.description").setValueFromOutside(objSkill.description);
            }
        },

        _getPayload: function () {
            var objData = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());

            if (a24Core.isEmpty(objData.position_types)) {
                objData.position_types = [];
            }

            return objData;
        },

        _createSkill: function () {
            this.set("_bAddSkillPopupLoading", true);

            var objData = this._getPayload();

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objSubdomainSysAdminStringsService").getString("skill")));

            this.get("_objRestSystemSkillsService").createSkill(this, "_objSkillCreatePromise", objData, () => {
                this._setSuccessResponse(this.get("_objStringsService").getTokenString("successAddItem", this.get("_objSubdomainSysAdminStringsService").getString("skill")));
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], (sCode, sStatus, objErrorThrown, objError) => {
                this._setErrorResponse(sCode, objError, this.get("_objStringsService").getTokenString("failAddItem", this.get("_objSubdomainSysAdminStringsService").getString("skill")));
            }));
        },

        _updateSkill: function () {
            this.set("_bAddSkillPopupLoading", true);

            var objData = this._getPayload();

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objSubdomainSysAdminStringsService").getString("skill")));

            this.get("_objRestSystemSkillsService").updateSkill(this, "_objSkillUpdatePromise", this.get("_objSelectedSkill._id"), objData, () => {
                this._setSuccessResponse(this.get("_objStringsService").getTokenString("successUpdateItem", this.get("_objSubdomainSysAdminStringsService").getString("skill")));
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], (sCode, sStatus, objErrorThrown, objError) => {
                this._setErrorResponse(sCode, objError, this.get("_objStringsService").getTokenString("failUpdateItem", this.get("_objSubdomainSysAdminStringsService").getString("skill")));
            }));
        },

        _setErrorResponse: function (sCode, objError, sSnackbarMessage) {
            if (sCode === "MIN_LENGTH") {
                this.setResponseError(objError.arrPath, "validationRestMinLength");
            } else if (sCode === "ENUM_MISMATCH") {
                this.setResponseError([objError.arrPath[0]], "validationRestUnsupportedValue");
            } else {
                this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            }
            this.set("_bAddSkillPopupLoading", false);
        },

        _setSuccessResponse: function (sSnackbarMessage) {
            this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            this.set("_bShowAddEditSkillPopup", false);
            this._refreshSkills();
        },

        _refreshSkills: function () {
            // Put the page in loading
            this.set("_bPageLoading", true);

            Ember.run.next(() => {
                Ember.run.schedule("afterRender", () => {
                    // Take page out of loading
                    this.set("_bPageLoading", false);
                });
            });
        },

        _onItemsSelect: function (arrSelectedItems) {
            if (!a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length === 1) {
                this.set("_objSelectedSkill", arrSelectedItems[0]);
            } else {
                this.set("_objSelectedSkill", null);
            }
        },

        actions: {
            cardDatagridTableNoneSelected: function () {
                this._onItemsSelect(null);
            },

            cardDatagridTableOneSelected: function (arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },

            cardDatagridTableMultiSelected: function (arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },

            onAddEditSkillPopupUnsavedConfirm: function () {
                this.set("_bShowAddEditSkillUnsavedData", false);
                this.set("_bShowAddEditSkillPopup", false);
            },

            onPopupAddEditSkillCancel: function () {
                this._onPopupAddEditSkillCancel();
            },

            onPopupAddEditSkillConfirm: function () {
                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    if (this.get("_bIsAdd")) {
                        this._createSkill();
                    } else {
                        this._updateSkill();
                    }
                }
            },

            onPageReload() {
                this.set("_bShowLoadFailedPopup", false);
                this._doServiceCalls();
            }
        }
    });
});