define("a24-staffshift-subdomain-sysadmin/services/rest-system-registration-body", ["exports", "a24-staffshift-subdomain-sysadmin/config/environment"], function (exports, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        /**
         * Route for creating registration body
         *
         * @param objContext - The context this function
         * @param sProperty - The Property name to get the promise object
         * @param objData - The registration body data
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  06 June 2019
         */
        createRegistrationBody: function (objContext, sProperty, objData, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/system/registration-body");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "POST", _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        },

        /**
         * Route for updating registration body
         *
         * @param objContext - The context this function
         * @param sProperty - The Property name to get the promise object
         * @param sRegistrationBodyId - The registration body id
         * @param objData - The registration body data
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  06 June 2019
         */
        updateRegistrationBody: function (objContext, sProperty, sRegistrationBodyId, objData, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/system/registration-body/" + sRegistrationBodyId);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "PUT", _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        },

        /**
         * Route to get specific registration body
         *
         * @param objContext - The context this function
         * @param sProperty - The Property name to get the promise object
         * @param sRegistrationBodyId - The registeration id to fetch basic info data for
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  07 June 2019
         */
        getRegistrationBody: function (objContext, sProperty, sRegistrationBodyId, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/system/registration-body/" + sRegistrationBodyId);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Route to get specific registration body status
         *
         * @param objContext - The context this function
         * @param sProperty - The Property name to get the promise object
         * @param sRegistrationBodyId - The registeration id to fetch basic info data for
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         * @param objQueryParams - Any extra query params for the get list
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  07 June 2019
         */
        getRegistrationBodyStatuses: function (objContext, sProperty, sRegistrationBodyId, funcSuccess, objFailure, objQueryParams) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/system/registration-body/" + sRegistrationBodyId + "/status");
            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Route to create registration body status
         *
         * @param objContext - The context this function
         * @param sProperty - The Property name to get the promise object
         * @param sRegistrationBodyId - The registeration body id
         * @param objData - The registration body status data
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  19 June 2019
         */
        createRegistrationBodyStatuses: function (objContext, sProperty, sRegistrationBodyId, objData, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/system/registration-body/" + sRegistrationBodyId + "/status");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "POST", _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        },

        /**
         * Route to update specific registration body status
         *
         * @param objContext - The context this function
         * @param sProperty - The Property name to get the promise object
         * @param sRegistrationBodyId - The registeration body id
         * @param sRegistrationBodyStatusId - The registeration body status id
         * @param objData - The registration body status data
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  19 June 2019
         */
        updateRegistrationBodyStatuses: function (objContext, sProperty, sRegistrationBodyId, sRegistrationBodyStatusId, objData, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/system/registration-body/" + sRegistrationBodyId + "/status/" + sRegistrationBodyStatusId);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "PUT", _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        },

        /**
         * Route to get specific registration body entries
         *
         * @param objContext - The context this function
         * @param sProperty - The Property name to get the promise object
         * @param sRegistrationBodyId - The registeration id to fetch basic info data for
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         * @param objQueryParams - Any extra query params for the get list
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  07 June 2019
         */
        getRegistrationBodyEntries: function (objContext, sProperty, sRegistrationBodyId, funcSuccess, objFailure, objQueryParams) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/system/registration-body/" + sRegistrationBodyId + "/entry");
            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Route to create registration body entry
         *
         * @param objContext - The context this function
         * @param sProperty - The Property name to get the promise object
         * @param sRegistrationBodyId - The registeration body id
         * @param objData - The registration body entry data
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  19 June 2019
         */
        createRegistrationBodyEntries: function (objContext, sProperty, sRegistrationBodyId, objData, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/system/registration-body/" + sRegistrationBodyId + "/entry");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "POST", _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        },

        /**
         * Route to update specific registration body entry
         *
         * @param objContext - The context this function
         * @param sProperty - The Property name to get the promise object
         * @param sRegistrationBodyId - The registeration body id
         * @param sRegistrationBodyEntryId - The registeration body entry id
         * @param objData - The registration body entry data
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  19 June 2019
         */
        updateRegistrationBodyEntries: function (objContext, sProperty, sRegistrationBodyId, sRegistrationBodyEntryId, objData, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/system/registration-body/" + sRegistrationBodyId + "/entry/" + sRegistrationBodyEntryId);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "PUT", _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        },

        /**
         * Route to get specific registration body entry statuses
         *
         * @param objContext - The context this function
         * @param sProperty - The Property name to get the promise object
         * @param sRegistrationBodyId - The registeration id to fetch basic info data for
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         * @param objQueryParams - Any extra query params for the get list
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  07 June 2019
         */
        getRegistrationBodyEntryStatuses: function (objContext, sProperty, sRegistrationBodyId, funcSuccess, objFailure, objQueryParams) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/system/registration-body/" + sRegistrationBodyId + "/entry-status");
            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Route to create registration body entry
         *
         * @param objContext - The context this function
         * @param sProperty - The Property name to get the promise object
         * @param sRegistrationBodyId - The registeration body id
         * @param objData - The registration body entry status data
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  19 June 2019
         */
        createRegistrationBodyEntryStatus: function (objContext, sProperty, sRegistrationBodyId, objData, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/system/registration-body/" + sRegistrationBodyId + "/entry-status");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "POST", _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        },

        /**
         * Route to update specific registration body entry
         *
         * @param objContext - The context this function
         * @param sProperty - The Property name to get the promise object
         * @param sRegistrationBodyId - The registeration body id
         * @param sRegistrationBodyEntryStatusId - The registeration body entry status id
         * @param objData - The registration body entry status data
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  19 June 2019
         */
        updateRegistrationBodyEntryStatus: function (objContext, sProperty, sRegistrationBodyId, sRegistrationBodyEntryStatusId, objData, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/system/registration-body/" + sRegistrationBodyId + "/entry-status/" + sRegistrationBodyEntryStatusId);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "PUT", _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        }
    });
});