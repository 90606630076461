define("a24-staffshift-subdomain-sysadmin/routes/access-denied", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        afterModel: function () {
            //At this point all needed js and css should have been loaded for the app and we can remove the app loader.
            //Better to put it here since it will stay on page untill context related call such as menu is done
            //The internal loading per page then displays until service calls are done
            Ember.$("#a24AppLoading").remove();
        }
    });
});