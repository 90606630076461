define("a24-staffshift-subdomain-sysadmin/router", ["exports", "a24-staffshift-subdomain-sysadmin/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    _objNavigation: Ember.inject.service("navigation"),
    _objGoogleAnalytics: Ember.inject.service("google-analytics"),
    didTransition: function () {
      this._super(...arguments);
      var objThis = this;
      var arrAnalyticsBlockedPages = ["index.index"];
      //Page url only updated in next loop, so have to do page view hit in next loop
      Ember.run.next(function () {
        //Set the new route details on the navigation service for later use
        //Note that iRouteTransitions has to be set last otherwise anything observing it will get the old
        //value for sCurrentRouteName and sCurrentUrl when doing get
        objThis.set("_objNavigation.sCurrentRouteName", objThis.get("currentRouteName"));
        objThis.set("_objNavigation.sCurrentUrl", objThis.get("url"));
        objThis.set("_objNavigation.iRouteTransitions", objThis.get("_objNavigation.iRouteTransitions") + 1);
        objThis.set('_objNavigation.objCurrentRouteParams', Ember.copy(objThis.get('_objNavigation.objRouteParams'), true));
        objThis.set('_objNavigation.objCurrentRouteQueryParams', Ember.copy(objThis.get('_objNavigation.objRouteQueryParams'), true));

        // Check if the current route is not in the blocked list. Then do google analytics call
        if (arrAnalyticsBlockedPages.indexOf(objThis.get('currentRouteName')) === -1) {
          objThis.get('_objGoogleAnalytics').doPageViewHit(objThis.get('url'));
        }
      });
    }
  });

  Router.map(function () {
    this.route('index', {
      path: '/'
    }, function () {
      this.route('welcome');
      this.route('user-list');
      this.route('invitation-list');
      this.route('permission-management');
      this.route('candidate-list');
      this.route('candidate-details');
      this.route('agency-list');
      this.route('agency-details');
      this.route('organisation-list');
      this.route('organisation-create');
      this.route('organisation-details');
      this.route('site-list');
      this.route('site-details');
      this.route('ward-list');
      this.route('ward-details');
      this.route('policy-type-list');
      this.route('policy-type-view', {
        path: '/policy-type-view/:policy_type_id'
      });
      this.route('policy-add', {
        path: '/policy-add/:policy_type_id'
      });
      this.route('skills');
      this.route('qualifications');
      this.route('registration-bodies');
      this.route('registration-body-view', {
        path: '/registration-body-view/:registration_body_id'
      });
      this.route('chatter-inbox');
      this.route('activity-types');
      this.route('social-link-types');
      this.route('chatter-inbox-old');
      this.route('job-titles');
      this.route('job-families');
    });

    //Handles any invalid url for the application
    this.route('invalid-route', {
      path: '/*bad_url' //the bad_url allows you access in the error route class to what was entered in the url
    });
    this.route('access-denied');
  });

  exports.default = Router;
});