define("a24-staffshift-subdomain-sysadmin/components/registration-body-add-edit-popup", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        bShowAddEditRegistrationBodyPopup: false,
        objRegistrationBody: null,
        arrDisplayPositionTypes: null,
        _bShowAddEditRegistrationBodyUnsavedData: false,
        _bShowAddEditRegistrationBodyPopup: false,
        _objSpecialAddEditRegistrationBodyPopupAction: null,
        _objRestSystemRegistrationBodiesService: Ember.inject.service("rest-system-registration-body"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSubdomainSysAdminStringsService: Ember.inject.service("a24-strings-subdomain-sysadmin"),
        _bAddRegistrationBodyPopupLoading: false,
        _objSubdomainSysAdminEnumsService: Ember.inject.service("a24-enums-subdomain-sysadmin"),
        _objEnumsService: Ember.inject.service("a24-enums"),
        _arrStatuses: null,
        _objSelectedCountryCode: null,
        _arrSelectedPositionTypes: null,
        _objSelectedStatus: null,
        _objOriginalData: null,
        _objSnackbarService: Ember.inject.service("snackbar"),
        // Init
        init: function () {
            this._super(...arguments);

            // Add validation
            this.set("objChildConfig", {
                "objChildren.name": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addMinLength(objMixin, 2);
                    a24Validation.addMaxLength(objMixin, 100);
                },
                "objChildren.short_name": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addMinLength(objMixin, 2);
                    a24Validation.addMaxLength(objMixin, 200);
                },
                "objChildren.country_code": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                },
                "objChildren.position_types": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                },
                "objChildren.status": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                }
            });

            this.set("_arrStatuses", this.get("_objSubdomainSysAdminEnumsService").getList("registrationBodyStatuses"));

            this.set("_arrCountryDropdown", this.get("_objEnumsService").getList("country"));

            this.set("_objSpecialAddEditRegistrationBodyPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: () => {
                    this._onPopupAddEditRegistrationBodyCancel();
                }
            });
        },
        // Computed header for add or edit
        _sAddEditRegistrationBodyHeaderPopup: Ember.computed("objRegistrationBody", function () {
            if (a24Core.isEmpty(this.get("objRegistrationBody"))) {
                return this.get("_objStringsService").getTokenString("addItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBody"));
            } else {
                return this.get("_objStringsService").getTokenString("editItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBody"));
            }
        }),
        // Computed boolean for is adding
        _bIsAdding: Ember.computed("objRegistrationBody", function () {
            return a24Core.isEmpty(this.get("objRegistrationBody"));
        }),
        // Run code when the popup opens
        onShowPopupChange: Ember.observer("bShowAddEditRegistrationBodyPopup", function () {
            if (this.get("bShowAddEditRegistrationBodyPopup") === true) {
                this.onPopupOpen();
            } else {
                this.set("_bShowAddEditRegistrationBodyPopup", false);
            }
        }),
        onPopupOpen: function () {
            // Clear errors and forms
            this.clearAllErrorMessages();
            this.clearForm();

            // Update bread crumb bar to avoid breaking due to url change
            if (!a24Core.isEmpty(this.get("setBreadCrumbAction"))) {
                this.get("setBreadCrumbAction")();
            }

            // Get the reason data
            var objRegistrationBody = this.get("objRegistrationBody");
            if (!a24Core.isEmpty(objRegistrationBody)) {
                this._populateInputFields(objRegistrationBody);
            }

            this.set("_bAddRegistrationBodyPopupLoading", false);
            this.set("_bShowAddEditRegistrationBodyPopup", true);

            Ember.run.next(() => {
                Ember.run.schedule("afterRender", () => {
                    // Save data for unsave data comparison
                    this.set("_objOriginalData", this.getPlainFormValueObject());
                });
            });
        },
        _onPopupAddEditRegistrationBodyCancel: function () {
            if (this.get("_bAddRegistrationBodiesPopupLoading")) {
                return;
            }

            var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

            if (bDataMatches) {
                if (!a24Core.isEmpty(this.get("onPopupCloseAction"))) {
                    this.get("onPopupCloseAction")();
                }
            } else {
                this.set("_bShowAddEditRegistrationBodyUnsavedData", true);
            }
        },
        _populateInputFields: function (objRegistrationBody) {
            if (!a24Core.isEmpty(objRegistrationBody.name)) {
                this.get("objChildren.name").setValueFromOutside(objRegistrationBody.name);
            }

            if (!a24Core.isEmpty(objRegistrationBody.short_name)) {
                this.get("objChildren.short_name").setValueFromOutside(objRegistrationBody.short_name);
            }

            var objSelectedCountryCode = this.get("_arrCountryDropdown").findBy("sValue", objRegistrationBody.country_code);

            if (!a24Core.isEmpty(objSelectedCountryCode)) {
                this.set("_objSelectedCountryCode", Ember.copy(objSelectedCountryCode, true));
            }

            var arrSelectedPositionTypes = [];
            for (var i = 0; i < objRegistrationBody.position_types.length; i++) {
                var objPositionType = this.get("arrDisplayPositionTypes").findBy("sValue", objRegistrationBody.position_types[i]);
                if (!a24Core.isEmpty(objPositionType)) {
                    arrSelectedPositionTypes.push(objPositionType);
                }
            }

            if (!a24Core.isEmpty(arrSelectedPositionTypes)) {
                this.set("_arrSelectedPositionTypes", Ember.copy(arrSelectedPositionTypes, true));
            }

            var objSelectedStatus = this.get("_arrStatuses").findBy("sValue", objRegistrationBody.status);

            if (!a24Core.isEmpty(objSelectedStatus)) {
                this.set("_objSelectedStatus", Ember.copy(objSelectedStatus, true));
            }
        },
        _getPayload: function () {
            var objData = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());

            if (a24Core.isEmpty(objData.position_types)) {
                objData.position_types = [];
            }

            return objData;
        },
        _createRegistrationBody: function () {
            this.set("_bAddRegistrationBodyPopupLoading", true);

            var objData = this._getPayload();

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBody")));

            this.get("_objRestSystemRegistrationBodiesService").createRegistrationBody(this, "_objRegistrationBodyCreatePromise", objData, objResponse => {
                this._setSuccessResponse(this.get("_objStringsService").getTokenString("successAddItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBody")));

                if (!a24Core.isEmpty(this.get("onRefreshProfRegistrationBodies"))) {
                    this.get("onRefreshProfRegistrationBodies")(objResponse);
                }
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], (sCode, sStatus, objErrorThrown, objError) => {
                this._setErrorResponse(sCode, objError, this.get("_objStringsService").getTokenString("failAddItem", this.get("_objSubdomainSysAdminStringsService").getString("registrationbody")));
            }));
        },
        _updateRegistrationBody: function () {
            this.set("_bAddRegistrationBodyPopupLoading", true);

            var objData = this._getPayload();
            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBody")));

            this.get("_objRestSystemRegistrationBodiesService").updateRegistrationBody(this, "_objRegistrationBodyUpdatePromise", this.get("objRegistrationBody._id"), objData, objResponse => {
                this._setSuccessResponse(this.get("_objStringsService").getTokenString("successUpdateItem", this.get("_objSubdomainSysAdminStringsService").getString("professionalRegisteredBody")));

                if (!a24Core.isEmpty(this.get("onRefreshProfRegistrationBodies"))) {
                    this.get("onRefreshProfRegistrationBodies")(objResponse);
                }
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], (sCode, sStatus, objErrorThrown, objError) => {
                this._setErrorResponse(sCode, objError, this.get("_objStringsService").getTokenString("failUpdateItem", this.get("_objSubdomainSysAdminStringsService").getString("registrationbody")));
            }));
        },

        _setErrorResponse: function (sCode, objError, sSnackbarMessage) {
            if (sCode === "MIN_LENGTH") {
                this.setResponseError(objError.arrPath, "validationRestMinLength");
            } else if (sCode === "ENUM_MISMATCH") {
                this.setResponseError([objError.arrPath[0]], "validationRestUnsupportedValue");
            } else {
                this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            }
            this.set("_bAddRegistrationBodyPopupLoading", false);
        },

        _setSuccessResponse: function (sSnackbarMessage) {
            this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            this.set("_bAddRegistrationBodyPopupLoading", false);

            if (!a24Core.isEmpty(this.get("onPopupCloseAction"))) {
                this.get("onPopupCloseAction")();
            }
        },
        actions: {
            onAddEditRegistrationBodyPopupUnsavedConfirm: function () {
                this.set("_bShowAddEditRegistrationBodyUnsavedData", false);
                if (!a24Core.isEmpty(this.get("onPopupCloseAction"))) {
                    this.get("onPopupCloseAction")();
                }
            },
            onPopupAddEditRegistrationBodyCancel: function () {
                this._onPopupAddEditRegistrationBodyCancel();
            },
            onPopupAddEditRegistrationBodyConfirm: function () {
                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    if (this.get("_bIsAdding")) {
                        this._createRegistrationBody();
                    } else {
                        this._updateRegistrationBody();
                    }
                }
            }
        }
    });
});