define("a24-staffshift-subdomain-sysadmin/services/a24-enums-staffshift-core", ["exports", "a24-ember-staffshift-core/services/a24-enums-staffshift-core"], function (exports, _a24EnumsStaffshiftCore) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _a24EnumsStaffshiftCore.default;
    }
  });
});