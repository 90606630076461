define("a24-staffshift-subdomain-sysadmin/components/page-chatter-inbox", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-sysadmin/config/environment"], function (exports, _didRenderChangesMixin, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        iInboxVersion: 1,

        _objChatterIframeComService: Ember.inject.service("chatter-iframe-com"),
        _objStaffshiftCoreStrings: Ember.inject.service("a24-strings-staffshift-core"),
        _objChatUserConversationRestService: Ember.inject.service("rest-chat-user-client-conversations"),

        //this needs to stay clean to match origin in post message events, so rather copy this and add params where needed
        //and do not alter this property
        _sIFrameUrl: "",
        _sFullIFrameUrl: null,
        _bLoading: true,
        _funcUnregister: null,

        _sChatUserId: null,
        _momentHelper: null,

        _objSelectedUserDetails: null,
        _objNavigation: Ember.inject.service("navigation"),

        init() {
            this._super(...arguments);

            // Note this is for react notifications to work as they require same document domain to push notification
            // messages. This does not effect any api calls as the origin does not change. Also all seems to work except
            // on test cases where it seems to break. Thus the env check
            if (_environment.default.environment !== "test") {
                document.domain = "staffshift.com";
            }

            this._requestNotificationPermission();

            if (a24Core.isEmpty(this.get("_sIFrameUrl"))) {
                this.set("_sIFrameUrl", _environment.default.sChatInboxUrl);
            }
            if (a24Core.isEmpty(this.get("_sFullIFrameUrl"))) {
                let sIFrameUrl = this.get("_sIFrameUrl");
                if (this.get("iInboxVersion") > 1) {
                    this.set("_sFullIFrameUrl", `${sIFrameUrl}?version=${this.get("iInboxVersion")}`);
                } else {
                    this.set("_sFullIFrameUrl", sIFrameUrl);
                }
            }

            if (!a24Core.isEmpty(this.get("_sIFrameUrl"))) {
                this.set("_funcUnregister", this.get("_objChatterIframeComService").receiveMessage(objMessage => {
                    this._handleMessages(JSON.parse(objMessage.data));
                }, this.get("_sIFrameUrl")));
            }
        },

        _requestNotificationPermission: function () {
            if ("Notification" in window) {
                if (Notification.permission !== "granted") {
                    Notification.requestPermission();
                }
            }
        },

        _postSessionData: function () {
            this.get("_objChatterIframeComService").postSessionData(true, this.get("_objStaffshiftCoreStrings").getString("inbox"), {}, this.get("_sIFrameUrl"), this.$("iframe")[0].contentWindow);
        },

        _postData: function (objData) {
            let objDataCopy = Ember.copy(objData, true);
            this.get("_objChatterIframeComService").postMessage(JSON.stringify(objDataCopy), this.get("_sIFrameUrl"), this.$("iframe")[0].contentWindow);
        },

        _notifyEventListener: function (event) {
            if (!a24Core.isEmpty(this.$("iframe"))) {
                this._postData({
                    type: "notify_event_listener",
                    data: {
                        event: event
                    }
                });
            }
        },

        _handleMessages: function (objData) {
            if (objData.type === "app_ready") {
                this.set("_bLoading", false);
                this._postSessionData();
            } else if (objData.type === "user_attributes") {
                if (!a24Core.isEmpty(objData.data) && !a24Core.isEmpty(objData.data.ss)) {
                    if (!a24Core.isEmpty(objData.data.ss.user) && !a24Core.isEmpty(objData.data.ss.user.id)) {
                        if (objData.data.ss.user.id !== this.get("_sChatUserId")) {
                            this.set("_sChatUserId", null);
                            Ember.run.scheduleOnce("afterRender", () => {
                                this.set("_sChatUserId", objData.data.ss.user.id);
                            });
                        }
                    } else {
                        this.set("_sChatUserId", null);
                    }

                    this.set("_objSelectedUserDetails", objData.data);
                } else if (!a24Core.isEmpty(objData.data)) {
                    this.set("_objSelectedUserDetails", objData.data);
                } else {
                    this.set("_objSelectedUserDetails", null);
                }
            } else if (objData.type === "desktop_notification") {
                this.get("_objChatterIframeComService").openDesktopNotification(this.get("_sIFrameUrl"), this.$("iframe")[0].contentWindow, objData.data);
            } else if (objData.type === "check_parent_has_focus") {
                this._postData({
                    type: objData.type + "_response",
                    data: {
                        hasFocus: window.document.hasFocus() || document.hasFocus()
                    },
                    requestId: objData.requestId
                });
            } else if (objData.type === "add_event_listener") {
                if (objData.data.location === "window") {
                    window.addEventListener(objData.data.event, event => {
                        this._notifyEventListener(event);
                    });
                } else if (objData.data.location === "document") {
                    document.addEventListener(objData.data.event, event => {
                        this._notifyEventListener(event);
                    });
                }
            } else if (objData.type === "remove_event_listener") {
                if (objData.data.location === "window") {
                    window.removeEventListener(objData.data.event, event => {
                        this._notifyEventListener(event);
                    });
                } else if (objData.data.location === "document") {
                    document.removeEventListener(objData.data.event, event => {
                        this._notifyEventListener(event);
                    });
                }
            }
        },

        willDestroyElement() {
            if (!a24Core.isEmpty(this.get("_funcUnregister"))) {
                this.get("_funcUnregister")();
            }
            //no need to do logout post message or something like that, chat app takes care of that.
            this._super(...arguments);
        },

        actions: {
            onGoToConversation: function (objConversation) {
                var objData = {
                    type: "select-conversation",
                    data: {
                        conversationId: objConversation._id
                    }
                };

                this.get("_objChatterIframeComService").postMessage(JSON.stringify(objData), this.get("_sIFrameUrl"), this.$("iframe")[0].contentWindow);
            }
        }
    });
});