define("a24-staffshift-subdomain-sysadmin/services/sticky-header-service", ["exports", "a24-ember-menu/services/sticky-header-service"], function (exports, _stickyHeaderService) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _stickyHeaderService.default;
    }
  });
});